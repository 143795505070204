import EventEmitter from '@/helpers/EventEmitter'

const Events = {
  LOAD: 'load',
  END: 'end',
  PLAY: 'play',
  PAUSE: 'pause',
  STATE: 'state'
}

export default class Track {
  visPaused = false

  constructor() {
    Object.assign(this, EventEmitter, {
      Events: Events
    })
  }

  bindEvents = () => {
    for (let key in this.Events) {
      let ev = this.Events[key]
      if (
        (window.Howler && typeof this.obj[`_on${ev}`] === 'object') || // howler
        !window.Howler // everything else
      ) {
        this.obj.on(ev, (s) => { this.emit(ev, s) })
      }
    }

    // vis change
    document.addEventListener('visibilitychange', this._onVisibilityChange)
  }

  _onVisibilityChange = evt => {
    if (document['hidden'] && this.obj.playing()) {
      this.obj.pause()
      this.visPaused = true
    } else {
      if (this.visPaused) {
        this.obj.play()
        this.visPaused = false
      }      
    }
  }
}
