import * as mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'
import 'url-search-params-polyfill'
import hasWebgl from 'src/helpers/hasWebgl'

let urlParams = new window.URLSearchParams(window.location.search)

// UTM SOURCE
let utmSource = urlParams.get('utm_source') || null

// Audio
let AudioContext = window.AudioContext || window.webkitAudioContext
let hasWebAudio = typeof AudioContext === 'function'

// Country
let country = null
if (urlParams.get('_region') !== null)
  country = urlParams.get('_region').toUpperCase()

const state = {
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight,
  device: '',
  scale: 1,
  fontSize: 10,
  isMobile: /(iPhone|iPad|iPod|Android)/i.test(navigator.userAgent),
  isIOS: /(iOS|iPod|iPad|iPhone)/i.test(navigator.userAgent),
  isAndroid: /Android/.test(navigator.userAgent),
  isSafari: /Safari/.test(navigator.userAgent),
  isWechat: /micromessenger/i.test(navigator.userAgent),
  isWeibo: /weibo/.test(navigator.userAgent),
  isInstagram: /instagram/.test(navigator.userAgent.toLowerCase()),
  isFacebook: /(fban|fbav)/i.test(navigator.userAgent.toLowerCase()),
  isUCBrowser: /UCBrowser/.test(navigator.userAgent),
  country: country,
  utmSource: utmSource,
  hasWebgl: hasWebgl,
  hasWebAudio: typeof AudioContext === 'function',
  isLowSpec: !hasWebgl || !hasWebAudio,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
