import Track from './Track'
import { Howl, Howler } from 'howler'

window.Howler = Howler


// Matches HTML5 MediaElement API
export default class Sound extends Track {
  constructor (url, html5, config) {

    super()
    this.config = config

    let sprite = null
    if (this.loopEnd) {
      sprite = {
        loop: [this.loopStart, this.loopEnd, true]
      }
    }

    this.obj = new Howl({
      src: url,
      preload: false,
      html5: html5,
      sprite: sprite
    })
    

    this.bindEvents()
  }

  get loopStart () {
    return this.config && this.config.loopStart ? this.config.loopStart : 0
  }

  get loopEnd () {
    return this.config && this.config.loopEnd ? this.config.loopEnd : null
  }

  load () {
    return new Promise(resolve => {
      this.obj.once('load', () => {
        resolve()
      })
      this.obj.load()
    })
  }

  destroy () {
    this.obj.unload()
  }

  play = (overlap) => {
    if (this.loopEnd) {
      this.soundID = 'loop'
    }

    if (this.soundID && !overlap){
      this.obj.play(this.soundID)
    } else {
      this.soundID = this.obj.play()
    }
  }

  pause = () => {
    this.obj.pause( this.soundID )
  }

  stop = () => {
    this.obj.stop( this.soundID)
  }

  fade = (to, duration) => {
    this.obj.fade(this.volume, to, duration)
  }

  get volume () {
    return this.obj.volume( this.soundID)
  }

  set volume (val) {
    this.obj.volume(val)
  }

  get muted () {
    return this.obj.muted
  }

  set muted (val) {
    this.obj.mute(val,  this.soundID)
  }

  get currentTime () {
    return this.obj.seek()
  }

  set currentTime (val) {
    this.obj.seek(val,  this.soundID)
  }

  get duration () {
    return this.obj.duration()
  }

  get paused () {
    return !this.obj.playing()
  }

  get rate () {
    return this.obj.rate()
  }

  set rate (val) {
    this.obj.rate(val)
  }

  get loop () {
    return this.obj.loop()
  }

  set loop (val) {
    this.obj.loop(val)
  }
}
