import * as PIXI from 'pixi.js'
import anime from 'animejs'
import EventEmitter from '@/helpers/EventEmitter'
import ResizeService from '@/services/ResizeService'
import ForceLandscape from '../helpers/ForceLandscape'
import ResnSpritesheetLoader from '../helpers/ResnSpritesheetLoader'


const Events = {
  LOAD_START: 'load_start',
  LOAD_PROGRESS: 'load_progress',
  LOAD_COMPLETE: 'load_complete',
  ENTER: 'enter',
  LEAVE: 'leave'
}

export default class Scene {
  stage = new PIXI.Container()
  loader = new PIXI.Loader()
  sprites = {}
  alwaysShown = false
  emitEvents = true
  _resizeTimer = null
  _isSetup = false
  _isLoading = false
  _loadProcessed = false

  constructor (assets = undefined) {
    Object.assign(this, EventEmitter)
    this.Events = Events

    this.stage.alpha = 0
    this.active = false
    this.assets = assets

    ResizeService.on(ResizeService.Events.RESIZE, this._resize)

    this.loader.use(ResnSpritesheetLoader.use);

    // setup loader and keep same style as other services
    this.loader.onProgress.add((loader, resource) => {
      if (!this.emitEvents) return
      this.emit(Events.LOAD_PROGRESS, {
        progress: loader.progress / 100,
        loader: loader,
        resource: resource
      })
    })

    this.addAssetsToLoader(this.assets)
  }

  destroy () {
    ResizeService.off(ResizeService.Events.RESIZE, this._resize)
  }

  get active () {
    return this.stage.visible
  }

  set active (val) {
    this.stage.visible = val
  }

  get loaded () {
    return this.loader.progress === 100 && this._loadProcessed
  }

  addAssetsToLoader (assets) {
    // add urls to loader
    if (assets) {
      assets.forEach((url) => {
        if (!this.loader.resources[url.id]) {
          this.loader.add(url.id, url.url)
        }
      })
    }
  }

  findAsset = (id) => {
    return this.assets.filter(a => {
      return a.id === id
    })[0]
  }

  load () {
    // promise based version of calling a PIXI loader load()
    return new Promise(resolve => {
      let complete = () => {
        this.loader.progress = 100
        this._loadProcessed = true
        this._isLoading = false

        if (!this._isSetup) {
          this.setup()
          this._isSetup = true
        }

        if (this.emitEvents) {
          this.emit(Events.LOAD_PROGRESS, {
            progress: 1,
            // loader: loader
          })
          this.emit(Events.LOAD_COMPLETE)
        }

        resolve()
      }

      if (!this.loaded) {
        const done = (loader, items) => {
          // check all spritesheets processed
          let proceed = true
          Object.keys(items).forEach(k => {
            let i = items[k]
            if (i.extension === 'json' && !i.spritesheets) {
              proceed = false
            }
          })

          // nope, keep waiting
          if (!proceed) {
            setTimeout(() => {
              done(loader, items)
            }, 250)
            return
          }

          // good to go!
          complete()
        }

        this._loadProcessed = false
        this._isLoading = true
        this.emit(Events.LOAD_START)
        if (this.loader.resources.length === 0) done()
        this.loader.load(done)
      }
      else {
        complete()
      }
    })
  }

  setup () {
    // ...
  }

  shown () {
    // ...
  }

  fadein (callback) {
    anime.remove(this.stage)
    anime({
      targets: this.stage,
      alpha: [0, 1],
      easing: 'easeOutExpo',
      duration: 750,
      complete: () => {
        if (callback) callback()
      }
    })
  }

  fadeout (callback) {
    anime.remove(this.stage)
    anime({
      targets: this.stage,
      alpha: [1, 0],
      easing: 'easeOutExpo',
      duration: 750,
      complete: () => {
        if (callback) callback()
      }
    })
  }

  enter (callback) {
    return new Promise(resolve => {
      if (this._isLoading) {
        // console.log('ENTER BUT WAIT FOR LOAD')
        setTimeout(() => { this.enter(resolve) }, 250)
        return
      }

      if (!this.loaded) {
        this.load().then(() => {
          // console.log('LOADED, ENTER AGAIN')
          setTimeout(() => { this.enter(resolve) }, 0)
        })
        return
      }

      this.resize()
      this.shown()
      this.active = true

      this.fadein(() => {  
        resolve()
        if (callback) callback()
        this.emit(Events.ENTER)
      })
    })
  }

  leave () {
    return new Promise(resolve => {
      this.fadeout(() => {
        this.active = false
        resolve()
        this.emit(Events.LEAVE)
      })
    })
  }

  _resize = () => {
    // debounce
    if (this._resizeTimer) clearTimeout(this._resizeTimer)
    this._resizeTimer = setTimeout(() => {
      if (this._isSetup) this.resize()
    }, 10)
  }

  resize () {
    // resize stage to rem scale
    // this.stage.scale.x = this.stage.scale.y = ResizeService.scale
    // and keep it centered
    this.stage.x = (ForceLandscape.width / 2) - (this.stage.width / 2)
    this.stage.y = (ForceLandscape.height / 2) - (this.stage.height / 2)
  }

  update () {

  }
}
