<template lang="pug">
#play
</template>

<script>
export default {
  name: 'play'
}
</script>

<style lang="sass" scoped>
@import "src/styles/common.sass"

#play
  +full()
  +flex-center()
  // disable pointer events for bg touch
  pointer-events: none
    
  p
    font-size: 2rem
</style>
