// let FontService
// if (window.FontFace) {
//   FontService = require('./src/FontServiceFontFace').default
// } else {
//   FontService = require('./src/FontServiceObserver').default
// }

// let AnalyticsService = import('./src/AnalyticsServiceGa')
let AnalyticsService = require('./src/AnalyticsServiceGtag').default

export default AnalyticsService

window.AnalyticsService = AnalyticsService // DEBUG
