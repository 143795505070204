import Raf from '@/helpers/Raf'

class ForceLandscape {
  _width = window.innerWidth
  _height = window.innerHeight
  _portrait = false
  
  constructor () {
    Raf.add(this.update)
  }
  
  get width () { return this._width }
  get height () { return this._height }
  get portrait () { return this._portrait }
  
  update = () => {
    let ow = window.innerWidth
    let oh = window.innerHeight
    
    this._width = ow
    this._height = oh
    this._portrait = false
    
    if (oh > ow) {
      this._width = oh
      this._height = ow
      this._portrait = true
    }
  }
}

export default new ForceLandscape()
