import Scene from './Scene'
import * as PIXI from 'pixi.js'
import { randomIntInRange, toRadians } from '@/helpers/MathUtils'
import { curvyCircle } from '@/helpers/Drawing'
import anime from 'animejs'
import ForceLandscape from '../helpers/ForceLandscape'
import FontService from '@/services/FontService'

class Loader extends Scene {
  progress = 0
  firstLoad = true
  
  constructor () {
    const assets = [
      {
        id: 'spinner',
        url: require('@/assets/img/spinner.png')
      },
      {
        id: 'spinner-outer',
        url: require('@/assets/img/loader/spinner-outer.png')
      },
      {
        id: 'spinner-inner',
        url: require('@/assets/img/loader/spinner-inner.png')
      },
      {
        id: 'loader-char',
        url: require('@/assets/img/loader/char.png')
      },
      // we'll use these buttons below for multiple other scenes
      // but load them here so we don't duplicate in those loaders
      {
        id: 'btn-casual',
        url: require('@/assets/img/select/casual.png')
      },
      {
        id: 'btn-timed',
        url: require('@/assets/img/select/timed.png')
      }
    ]
    
    super(assets)
  }
  
  setup () {
    this.makeIndicator()

    if (FontService.loaded) this.makeTitleCard()
    else FontService.on(FontService.Events.LOADED, this.makeTitleCard)
  }

  makeIndicator () {
    this.indicator = new PIXI.Container()
    
    // circle
    this.circle = new PIXI.Graphics()
    this.circle.beginFill(0xffffff)
    this.circle.drawCircle(60, 60, 60)
    this.circle.endFill()
    this.indicator.addChild(this.circle)
    
    // spinner
    this.spinner = new PIXI.Sprite(this.loader.resources['spinner'].texture)
    this.spinner.anchor.x = this.spinner.anchor.y = 0.5
    this.spinner.scale.x = this.spinner.scale.y = 0.5
    this.spinner.x = this.indicator.width / 2
    this.spinner.y = this.indicator.height / 2
    this.indicator.addChild(this.spinner)
    
    this.stage.addChild(this.indicator)
  }

  makeTitleCard = () => {
    // bg
    let bg = new PIXI.Graphics()
    bg.beginFill(0xffffd6)
    bg.drawRoundedRect(0, 0, ForceLandscape.width - 28, ForceLandscape.height - 28, 5)
    bg.endFill()
    this.stage.addChild(bg)
    this.titleBg = bg

    // mask
    let mask = new PIXI.Graphics()
    mask.beginFill(0xffffff)
    mask.drawRoundedRect(0, 0, bg.width, bg.height, 5)
    mask.endFill()
    this.stage.addChild(mask)
    this.titleMask = mask

    this.titleCard = new PIXI.Container()
    this.titleCard.mask = mask

    // texts
    let title = new PIXI.Text('I SPY', {
      fontFamily: 'Dirk Black',
      fontSize: 103,
      fill: 0xe6754b,
      trim: true
    })
    this.titleCard.addChild(title)
    this.titleText = title
    
    let welcome = new PIXI.Text('Welcome to', {
      fontFamily: 'Fenway Park JF',
      fontSize: 37,
      fill: 0xe6754b,
      padding: 7,
      trim: true
    })
    welcome.x = (title.width / 2) - (welcome.width / 2)
    welcome.y = (title.height / 2) - (welcome.height / 2) - 70
    this.titleCard.addChild(welcome)    

    // char
    let char = new PIXI.Sprite(this.loader.resources['loader-char'].texture)
    char.scale.x = char.scale.y = 0.5
    char.x = (title.width / 2) + 80
    char.y = (title.height / 2) - 160
    this.titleCard.addChild(char)

    // spinner
    let spinner = new PIXI.Container()
    let outer = new PIXI.Sprite(this.loader.resources['spinner-outer'].texture)
    spinner.addChild(outer)
    let inner = new PIXI.Sprite(this.loader.resources['spinner-inner'].texture)
    inner.anchor.x = inner.anchor.y = 0.5
    inner.x = outer.width / 2
    inner.y = outer.height / 2
    spinner.addChild(inner)
    this.titleSpinner = inner
    spinner.scale.x = spinner.scale.y = 0.5
    spinner.x = (title.width / 2) - (spinner.width / 2)
    spinner.y = (title.height / 2) - (spinner.height / 2) + 100
    this.titleCard.addChild(spinner)

    this.stage.addChild(this.titleCard)

    this.resize()
  }
  
  // // overload fadein/fadeout to just appear
  // fadein (callback) {
  //   this.active = true
  //   this.stage.alpha = 1
  //   callback()
  // }  
  
  // fadeout (callback) {
  //   this.active = false
  //   this.stage.alpha = 0
  //   callback()
  // }
  
  resize () {
    if (this.titleBg) {
      let bg = this.titleBg
      bg.clear()
      bg.beginFill(0xffffd6)
      bg.drawRoundedRect(0, 0, ForceLandscape.width - 28, ForceLandscape.height - 28, 5)
      bg.endFill()

      let mask = this.titleMask
      mask.clear()
      mask.beginFill(0xffffff)
      mask.drawRoundedRect(0, 0, bg.width, bg.height, 5)
      mask.endFill()

      let title = this.titleCard
      title.x = (ForceLandscape.width / 2) - (this.titleText.width / 2)
      title.y = (ForceLandscape.height / 2) - (this.titleText.height / 2)
    }

    this.indicator.x = (this.stage.width / 2) - (this.indicator.width / 2)
    this.indicator.y = (this.stage.height / 2) - (this.indicator.height / 2)

    this.stage.x = 14
    this.stage.y = 14
  }
  
  shown () {
    if (this.titleCard) {
      this.titleBg.alpha = this.firstLoad ? 1 : 0
      this.titleCard.alpha = this.firstLoad ? 1 : 0
    }
    this.firstLoad = false
  }
  
  update () {
    this.spinner.angle += 2
    if (this.titleSpinner) this.titleSpinner.angle += 2
  }
}

export default new Loader()
