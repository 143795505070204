export const setOpenid = (state, openid) => {
  state.openid = openid
}

export const setUsername = (state, username) => {
  state.username = username
}

export const setIsFollower = (state, isFollower) => {
  state.isFollower = isFollower
}

export const setReady = state => {
  state.ready = true
}
