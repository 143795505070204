import Scene from './Scene'
import * as PIXI from 'pixi.js'
import Lerp from '@/helpers/Lerp'
import Touch from '../helpers/Touch'
import { clamp, toRadians, randomIntInRange } from '@/helpers/MathUtils'
import tutorial from '../manifests/Tutorial'
import anime from 'animejs'
import ForceLandscape from '../helpers/ForceLandscape'
import PixiService from '@/services/PixiService'
import FontService from '@/services/FontService'
import Padded from '../helpers/Padded'
import Sounds from '@/services/PixiService/Sounds'

class Hurry extends Scene {    
  constructor () {
    const assets = []
    
    super(assets)
  }

  setup () {
    if (FontService.loaded) this.makeThings()
    else FontService.on(FontService.Events.LOADED, this.makeThings)      
  }

  makeThings = () => {
    let ti = {
      base: new PIXI.Container(),
      timeline: anime.timeline({ autoplay: false }),
      texts: []
    }

    // text styles
    let bigStyle = {
      fontFamily: 'Dirk Black',
      fontSize: 140,
      fill: 0xffffd5,
      trim: true,
      letterSpacing: 2
    }
    let medStyle = Object.assign({}, bigStyle, { fontSize: 80 })

    // text lines
    let texts = [
      { text: 'HURRY!', style: medStyle },
    ]

    // make text layers
    texts.forEach((ts, index) => {
      let t = new PIXI.Text(ts.text, ts.style)
      t.anchor.set(0.5, 0.5)
      ti.base.addChild(t)
      ti.texts.push(t)

      let o = 1000 * index

      // appear
      let appear = {
        easing: 'easeOutExpo',
        duration: 200,
        offset: o
      }
      ti.timeline.add(Object.assign({}, appear, {
        targets: t,
        alpha: [0, 1],
      }))
      ti.timeline.add(Object.assign({}, appear, {
        targets: t.scale,
        x: [0.75, 0.95],
        y: [0.75, 0.95],
      }))

      // slight scale
      let slight = {
        easing: 'linear',
        duration: 1200,
        offset: o + 200
      }
      ti.timeline.add(Object.assign({}, slight, {
        targets: t.scale,
        x: [0.95, 1],
        y: [0.95, 1],
      }))

      // disappear
      let disappear = {
        easing: 'easeOutExpo',
        duration: 200,
        offset: o + 1400
      }
      ti.timeline.add(Object.assign({}, disappear, {
        targets: t,
        alpha: [1, 0],
      }))
      ti.timeline.add(Object.assign({}, disappear, {
        targets: t.scale,
        x: [1, 0.75],
        y: [1, 0.75],
      }))
    })

    this.stage.addChild(ti.base)
    this.content = ti
  }
  
  shown () {
    this.stage.alpha = 1
  }

  fadein (callback) {
    this.active = true
    
    let tl = this.content.timeline
    tl.complete = () => {
      callback()
    }
    tl.restart()
  }

  fadeout (callback) {
    this.active = false
    callback()
  }

  resize () {
    this.stage.x = (ForceLandscape.width / 2)
    this.stage.y = (ForceLandscape.height / 2)
  }
  
  update () {
    // visible?
    let play = PixiService.getScene('play')
    this.content.base.visible = play.mode === play.MODES.TIMED
  }
}

export default new Hurry()
