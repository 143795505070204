import Scene from './Scene'
import * as PIXI from 'pixi.js'
import Touch from '../helpers/Touch'
import Lerp from '@/helpers/Lerp'
import ResizeService from '@/services/ResizeService'
import { clamp, toRadians, randomIntInRange } from '@/helpers/MathUtils'
import manifest from '../manifests/PlayScenes'
import anime from 'animejs'
import { curvyCircle, curvyRect } from '@/helpers/Drawing'
import router from '@/router'
import store from '@/store'
import ForceLandscape from '../helpers/ForceLandscape'
import PixiService from '@/services/PixiService'
import Padded from '../helpers/Padded'
import Sounds from '@/services/PixiService/Sounds'
import { GlowFilter } from '@pixi/filter-glow'
// import { OutlineFilter } from '@pixi/filter-outline'

const STATES = {
  TUTORIAL: 'tutorial',
  MAIN: 'main',
  RESULTS: 'results'
}

const MODES = {
  CASUAL: 'casual',
  TIMED: 'timed'
}

const WAIT_INITIAL = 60 * 1000 * 2 // 2 minutes
const WAIT_DECREASE = 1000 * 5 // 5 seconds
const WAIT_MIN = 1000 * 30 // 30 seconds

class Play extends Scene {
  pos = {
    x: new Lerp(),
    y: new Lerp()
  }

  find = {
    item: 0,
    found: [],
    score: 0,
    timer: {
      active: false,
      start: Date.now(),
      wait: WAIT_INITIAL,
      paused: false,
      pausedAt: 0
    }
  }

  facts = {
    current: 0,
    used: []
  }

  state = STATES.TUTORIAL

  constructor () {
    const assets = [
      {
        id: 'timer-spinner',
        url: require('@/assets/img/play/timer-spinner.png')
      }
    ]

    super(assets)

    this.STATES = STATES
    this.sceneId = undefined
    this.mode = undefined
    this.MODES = MODES
    this.data = undefined
    this.loadedScenes = []
    this.items = []
    this.gameScale = 1
  }

  loadedScene (id) {
    return this.loadedScenes.indexOf(id) !== -1
  }

  validScene (id) {
    return id >= manifest.length ? 0 : id
  }

  async load () {
    // add things to loader?
    if (typeof this.sceneId === 'number') {
      // correct scene id
      if (this.sceneId >= manifest.length) {
        this.sceneId = 0
      }
      // reset loader
      this.loader.reset()

      this.data = manifest[this.sceneId]

      let items = [
        {
          id: `s${this.sceneId}_bg`,
          url: this.data.bg
        },
        {
          id: `s${this.sceneId}_bg__x2`,
          url: this.data.bgx2
        },
        {
          id: this.data.sheet
        },
        {
          id: `s${this.sceneId}_close`,
          url: this.data.close
        },
        {
          id: `s${this.sceneId}_hint`,
          url: this.data.hint
        },
      ]

      this.assets = this.assets.concat(items)
      this.addAssetsToLoader(this.assets)
    }

    this.loadedScenes.push(this.sceneId)
    await super.load()
  }  

  shown () {
    // reset everytime we enter
    this.resetScene()
    this.state = STATES.TUTORIAL
    this.pos.x.to = this.pos.y.to = 0
    this.pos.x.set()
    this.pos.y.set()
    this.touch.reset()
    this.find.timer.wait = WAIT_INITIAL
    this.find.item = 0
    this.find.found = []

    // reset score
    this.updateScore(0)
    store.commit('app/setScore', 0)

    // show tutorial
    PixiService.getScene('tutorial').enter()

    // hide results
    PixiService.getScene('results').leave()

    this.touchEnd()
  }

  setup () {
    this.scale = 1 / PixiService.resolution
    this.sheet = this.loader.resources[this.data.sheet]

    this.createGameBase()
    this.createTimer()
    this.createScore()
    this.createClose()
    this.addClose()
    this.createHint()
    this.addHint()

    // listen to tutorial events
    let tutorial = PixiService.getScene('tutorial')
    tutorial.on(tutorial.Events.LEAVE, this.onTutorialLeave)

    // listen to results events
    let results = PixiService.getScene('results')
    results.on(results.Events.LEAVE, this.onResultsLeave)

    // pause the game when loses focus
    document.addEventListener('visibilitychange', this._onVisibilityChange)

    // start!
    this.nextItem()
  }

  _onVisibilityChange = evt => {
    let route = router.currentRoute.name
    if (route === 'play' && this.state === STATES.MAIN) {
      if (document['hidden']) {
        this.find.timer.paused = true
        this.find.timer.pausedAt = Date.now()
        if (this.timer.pulse.progress > 0) {
          this.timer.pulse.pause()
          this.timer.pulsePaused = true
        }
      } else {
        // resume
        this.find.timer.start += Date.now() - this.find.timer.pausedAt
        if (this.timer.pulsePaused) {
          this.timer.pulse.play()
          this.timer.pulsePaused = false
        }
        if (this.find.timer.pausedTimer) clearTimeout(this.find.timer.pausedTimer)
        this.find.timer.pausedTimer = setTimeout(() => {
          this.find.timer.paused = false
        }, 0)
      }
    }
  }

  destroyScene (fully = false) {
    if (this.game) {
      // first remove bg
      this.game.removeChild(this.sprites.bg)
      this.sprites.bg.destroy(fully)
      this.sprites.bg = null
      delete this.sprites.bg

      // remove close
      this.close.removeChild(this.sprites.close)
      this.sprites.close.destroy(fully)
      this.sprites.close = null
      delete this.sprites.close

      // remove hint
      this.hint.removeChild(this.sprites.hint)
      this.sprites.hint.destroy(fully)
      this.sprites.hint = null
      delete this.sprites.hint

      // then remove items
      this.items.forEach((i, index) => {
        this.game.removeChild(i)
        i.off('click')
        i.off('tap')
        i.destroy(fully)
        this.sprites[`item_${index}`] = null
        this.items[index] = null
        delete this.sprites[`item_${index}`]
        delete this.items[index]
      })
      this.items = []
    }
  }

  resetScene () {
    if (this.game) {
      this.destroyScene()

      // update data and sheet
      this.data = manifest[this.sceneId]
      this.sheet = this.loader.resources[this.data.sheet]

      // update bgm
      if (typeof this.data.bgm === 'string') {
        this.data.bgm = Sounds[this.data.bgm]
      }

      // reset used facts
      this.facts.current = 0
      this.facts.used = []

      // then add new ones
      this.addBg()
      this.addHint()
      this.addClose()
      this.addGameItems()

      // final resize
      this.resize()
    }
  }

  addBg () {
    // background
    const bg = `s${this.sceneId}_bg${this.scale === 1 ? '' : '__x2'}`
    this.sprites.bg = new PIXI.Sprite(this.loader.resources[bg].texture)
    this.sprites.bg.scale.x = this.sprites.bg.scale.y = this.scale
    // this.sprites.bg.alpha = 0.5
    this.game.addChild(this.sprites.bg)
  }

  addGameItems () {
    // add items to scene
    const gameItemNames = this.scale === 1 ? /Target_[0-9]+\.png/i : /Target_[0-9]+__x2\.png/i

    this.sheet.textures = this.sheet.spritesheets.reduce((t, s) => Object.assign(t, s.textures), {})

    let index = 0
    this.sheet.spritesheets
      .forEach((items, i) => {

        Object.keys(items.textures)
          .filter(ak => gameItemNames.test(ak))
          .forEach((ak) => {
            let a = items.textures[ak]
            let p = 10 * PixiService.resolution

            let inside = new PIXI.Sprite(a)
            const glow = new GlowFilter(10, 5, 0, 0xffffd6, 0.1)
            glow.padding = 5
            // const outline = new OutlineFilter(5, 0xffffd6, 1)
            // outline.padding = 5 * PixiService.resolution
            inside.filters = [ glow ]

            let s = new Padded(inside, p, p)
            s.visible = false
            s.alpha = 0
            this.game.addChild(s)
            s.position.x = (items.spritesheet.data.frames[ak].position.x * this.scale) - (p * this.scale)
            s.position.y = (items.spritesheet.data.frames[ak].position.y * this.scale) - (p * this.scale)
            s.scale.x = s.scale.y = this.scale
            s.interactive = true
            s.itemId = index // keep record of item id
            s.itemRef = ak // keep record of texture name
            s.on('click', this.itemClick)
            s.on('tap', this.itemClick)
            this.sprites[`item_${index}`] = s
            this.items[index] = s // add to array of only items
            index++
          })

      })
  }

  addHint () {
    const h = `s${this.sceneId}_hint`
    this.sprites.hint = new PIXI.Sprite(this.loader.resources[h].texture)
    this.sprites.hint.scale.x = this.sprites.hint.scale.y = 0.5
    this.hint.addChild(this.sprites.hint)
  }

  addClose () {
    const c = `s${this.sceneId}_close`
    this.sprites.close = new PIXI.Sprite(this.loader.resources[c].texture)
    this.sprites.close.scale.x = this.sprites.close.scale.y = 0.5
    this.close.addChild(this.sprites.close)
  }

  createGameBase () {
    this.game = new PIXI.Container()
    this.touch = new Touch(this.game, { out: true })
    this.touch.on(this.touch.Events.START, this.touchStart)
    this.touch.on(this.touch.Events.MOVE, this.touchMove)
    this.touch.on(this.touch.Events.END, this.touchEnd)

    this.addBg()
    this.addGameItems()

    // add to main stage
    this.stage.addChild(this.game)
  }

  createTimer () {
    // timer
    this.timer = {
      base: new PIXI.Container(),
      pulsed: new PIXI.Container(),
      char: new PIXI.Container(),
      alphaTo: new Lerp()
    }

    // circle
    let circle = new PIXI.Graphics()
    circle.beginFill(0xffffd6)
    circle.drawCircle(60, 60, 60)
    circle.endFill()
    this.timer.pulsed.addChild(circle)
    this.timer.circle = circle

    // spinner progress
    this.timer.spinner = new PIXI.Graphics()
    this.timer.pulsed.addChild(this.timer.spinner)

    // spinner sprite
    this.timer.spinnerSprite = new PIXI.Sprite(this.loader.resources['timer-spinner'].texture)
    this.timer.spinnerSprite.scale.x = this.timer.spinnerSprite.scale.y = 0.5
    this.timer.spinnerSprite.anchor.set(0.5, 0.5)
    this.timer.spinnerSprite.x = 60
    this.timer.spinnerSprite.y = 60
    this.timer.spinnerSprite.mask = this.timer.spinner
    this.timer.pulsed.addChild(this.timer.spinnerSprite)

    // pulsed container
    this.timer.pulsed.pivot.x = circle.width / 2
    this.timer.pulsed.pivot.y = circle.height / 2
    this.timer.pulsed.x = circle.width / 2
    this.timer.pulsed.y = circle.height / 2
    this.timer.base.addChild(this.timer.pulsed)

    // char container
    this.timer.char.x = circle.width / 2
    this.timer.char.y = circle.height / 2
    this.timer.base.addChild(this.timer.char)

    this.stage.addChild(this.timer.base)

    this.createTimerPulse()
  }

  createTimerPulse () {
    let tl = anime.timeline({ autoplay: false })
    for (let x = 0; x < 10; x++) {
      let o = 1000 * x
      tl.add({
        targets: this.timer.pulsed.scale,
        duration: 200,
        x: 1.03,
        y: 1.03,
        easing: 'easeInOutQuart',
        offset: o
      })
      tl.add({
        targets: this.timer.char.scale,
        duration: 200,
        x: 1.02,
        y: 1.02,
        easing: 'easeInOutQuart',
        offset: o + 50
      })

      tl.add({
        targets: this.timer.pulsed.scale,
        duration: 200,
        x: 1,
        y: 1,
        easing: 'easeInOutQuart',
        offset: o + 200
      })
      tl.add({
        targets: this.timer.char.scale,
        duration: 200,
        x: 1,
        y: 1,
        easing: 'easeInOutQuart',
        offset: o + 250
      })

      tl.add({
        targets: this.timer.pulsed.scale,
        duration: 600,
        x: 1,
        y: 1,
        easing: 'linear',
        offset: o + 400
      })
      tl.add({
        targets: this.timer.char.scale,
        duration: 550,
        x: 1,
        y: 1,
        easing: 'linear',
        offset: o + 450
      })
    }

    this.timer.pulse = tl
  }

  createScore () {
    // score
    this.score = {
      base: new PIXI.Container(),
      alphaTo: new Lerp()
    }

    // circle
    let circle = new PIXI.Graphics()
    circle.beginFill(0xe6754b)
    circle.drawCircle(21, 21, 21)
    circle.endFill()
    this.score.base.addChild(circle)
    this.score.circle = circle

    // text
    this.score.text = new PIXI.Text('0', {
      fontFamily: 'Avenir Black',
      fontSize: 15,
      fill: 0xffffd6,
      align: 'center',
      wordWrap: true,
      wordWrapWidth: 42
    })
    this.score.text.anchor.set(0.5, 0.5)
    this.score.text.x = circle.width / 2
    this.score.text.y = circle.height / 2
    this.score.base.addChild(this.score.text)

    this.stage.addChild(this.score.base)
  }

  createHint () {
    this.hint = new PIXI.Container()
    // this.hint.scale.x = this.hint.scale.y = this.scale
    this.hint.interactive = true
    this.hint.cursor = 'pointer'
    this.hint.on('click', this.onHint)
    this.hint.on('tap', this.onHint)
    this.stage.addChild(this.hint)

    // disable for now
    this.hint.alphaTo = new Lerp()
  }

  createClose () {
    this.close = new PIXI.Container()
    // this.close.scale.x = this.close.scale.y = this.scale
    this.close.interactive = true
    this.close.cursor = 'pointer'
    this.close.on('click', this.onClose)
    this.close.on('tap', this.onClose)
    this.stage.addChild(this.close)

    // disable for now
    this.close.alphaTo = new Lerp()
  }

  startTimedIntro () {
    return new Promise(resolve => {
      if (this.mode === MODES.TIMED) {
        PixiService.getScene('countdown').enter().then(() => {
          resolve()
        })
      } else {
        // immediately resolve cuz skipped
        resolve()
      }
    })
  }

  onHint = () => {
    const e = this.touch
    const s = this.sprites.bg
    const sw = s.width * this.gameScale
    const sh = s.height * this.gameScale
    const find = this.items[this.find.item]

    // position map nearby item
    let rx = randomIntInRange(ForceLandscape.width * 0.1, ForceLandscape.width * 0.3)
    rx *= randomIntInRange(1, 2) === 2 ? -1 : 1
    let ry = randomIntInRange(ForceLandscape.height * 0.1, ForceLandscape.height * 0.3)
    ry *= randomIntInRange(1, 2) === 1 ? -1 : 1
    const xp = (find.x + (find.width / 2) + rx) / sw
    const yp = (find.y + (find.height / 2) + ry) / sh
    const minX = (sw / 2) - (ForceLandscape.width / 2)
    const minY = (sh / 2) - (ForceLandscape.height / 2)
    const x = (xp * sw) - (sw / 2)
    const y = (yp * sh) - (sh / 2)
    e.x = clamp(x, -minX, minX)
    e.y = clamp(y, -minY, minY) * (ForceLandscape.portrait ? -1 : 1)
    this.pos.x.to = e.x
    this.pos.y.to = ForceLandscape.portrait ? -e.y : e.y

    // flash item
    anime.remove([find])
    anime({
      targets: [find],
      alpha: [0, 0.2],
      loop: 6,
      duration: 150,
      direction: 'alternate',
      easing: 'linear',
      complete: () => {
        this.flashing = false
      }
    })

    // fade out ui for a sec
    this.flashing = true
  }

  onClose = () => {
    // stop countdown sound just in case
    Sounds.countdown.stop()
    // end game
    this.ended()
  }

  onTutorialLeave = async () => {
    if (this.state !== STATES.TUTORIAL) return

    await this.startTimedIntro()

    this.find.timer.start = Date.now()
    this.state = STATES.MAIN

    this.nextItem()
    this.pulseTimerChar()
    this.touchEnd()
  }

  onResultsLeave = async () => {
    if (this.state !== STATES.RESULTS) return

    let results = PixiService.getScene('results')
    if (results.closeAction === 'replay') {
      await this.startTimedIntro()
      // restart game without tutorial
      // this.pos.x.to = this.pos.y.to = 0
      // this.pos.x.set()
      // this.pos.y.set()
      // this.touch.reset()
      this.find.timer.wait = WAIT_INITIAL
      this.find.found = []
      this.find.timer.start = Date.now()
      this.state = STATES.MAIN
      this.nextItem()
    }

    // reset score
    this.updateScore(0)
    store.commit('app/setScore', 0)

    this.touchEnd()
  }

  fadein (callback) {
    super.fadein(callback)

    // fade in music
    this.data.bgm.play()
    this.data.bgm.fade(1, 1000)
  }

  fadeout (callback) {
    super.fadeout(callback)

    // but also hide tutorial
    PixiService.getScene('tutorial').leave()

    this.data.bgm.fade(0, 1000)
    setTimeout(() => {
      this.data.bgm.pause()
    }, 1100)
  }

  ended () {
    // update score
    store.commit('app/setScore', this.find.score)

    // results
    this.state = STATES.RESULTS
    PixiService.getScene('results').enter()
  }

  nextItem = () => {
    // reset found array because we've already found them all
    if (this.find.found.length >= this.items.length) {
      this.find.found = []
      // game over :)
      return this.ended()
    }

    // hide previous
    this.items[this.find.item].visible = false

    // select an item that hasn't been used previously
    let selected = false
    let num = 0
    while (selected === false) {
      num = Math.round(randomIntInRange(0, this.items.length - 1))
      if (this.find.found.indexOf(num) === -1) {
        this.find.found.push(num)
        selected = num
      }
    }

    this.find.item = num
    this.find.timer.start = Date.now()
    this.find.timer.active = true
    this.updateTimer(true)

    // show new
    this.items[num].visible = true

    // timer char
    if (this.sprites.char) {
      anime.remove([this.sprites.char])
      anime.remove([this.sprites.char.scale])
      this.timer.char.removeChild(this.sprites.char)
      this.sprites.char.destroy()
    }
    let ref = this.items[num].itemRef
    let tex = this.sheet.textures[ref]
    let forceLarger = (tex.trim.height * this.scale) < 50 && (tex.trim.width * this.scale) < 60
    let scale = this.scale
    // for lower resolution screens, for larger sprite
    if (forceLarger && this.scale === 1) {
      ref = ref.replace('.png', '__x2.png')
      tex = this.sheet.textures[ref]
      scale = 0.5
    }    
    let char = new PIXI.Sprite(tex)
    char.anchor.x = char.anchor.y = 0.5
    char.scale.x = char.scale.y = forceLarger ? (scale * 2) : this.scale
    // char.x = char.y = this.timer.circle.width / 2
    // char.alpha = 0.5
    this.sprites.char = char
    this.timer.char.addChild(this.sprites.char)

    // fade in char
    anime({
      targets: this.sprites.char,
      alpha: [0, 1],
      duration: 1000,
      easing: 'easeOutQuart'
    })

    // scale in char
    anime({
      targets: this.sprites.char.scale,
      x: [char.scale.x * 0.7, char.scale.x],
      y: [char.scale.y * 0.7, char.scale.y],
      duration: 7500,
      easing: 'easeOutSine'
    })

    // also pulse it
    this.pulseTimerChar()

    // stop low timer pulse
    this.timer.pulse.pause()
    this.timer.pulse.seek(0)

    // stop countdown sound just in case
    Sounds.countdown.stop()

    return num
  }

  pulseTimerChar () {
    // anime.remove([this.sprites.char])
    // anime({
    //   targets: this.sprites.char,
    //   alpha: [0.5, 1],
    //   loop: 5,
    //   duration: 250,
    //   direction: 'alternate',
    //   easing: 'linear'
    // })
  }

  updateTimer = (force) => {
    if (
      (
        this.mode === MODES.CASUAL ||
        this.state !== STATES.MAIN
      ) && !force) return

    let now = Date.now()
    let progress = clamp((now - this.find.timer.start) / this.find.timer.wait, 0, 1)

    // 10 second countdown!
    if (
      now - this.find.timer.start >= this.find.timer.wait - 9000 && 
      !Sounds.countdown.obj.playing()
      ) {
      // play clock sound
      Sounds.countdown.play()
      // show "HURRY!"
      PixiService.getScene('hurry').enter()
      // pulse timer
      this.pulseTimer()
    }

    if (progress === 1) {
      // game over :(
      this.ended()
    }

    // draw spinner
    this.timer.spinner.clear()
    this.timer.spinner.lineStyle(20, 0xe6764c, 1, 0)
    this.timer.spinner.arc(
      this.timer.circle.width / 2,
      this.timer.circle.height / 2,
      50,
      toRadians(-90 + 0),
      toRadians(-90 + (365 * progress))
    )
  }

  updateHint () {
    let now = Date.now()
    // 66% of find time for timed, 15 seconds for casual
    let wait = this.mode === MODES.TIMED ? this.find.timer.wait * 0.66 : 15000

    // show hint?    
    if (
      this.state === STATES.MAIN && 
      now - this.find.timer.start >= wait
    ) {
      this.hint.interactive = true
      this.hint.alphaTo.to = 1
    } else {
      this.hint.interactive = false
      this.hint.alphaTo.to = 0
    }   
  }

  pulseTimer () {
    this.timer.pulse.restart()
  }

  updateScore = (amt) => {
    this.find.score = amt
    this.score.text.text = amt

    anime({
      targets: this.score.text.scale,
      x: [1.8, 1],
      y: [1.8, 1]
    })
  }

  itemClick = (e) => {
    const target = e.currentTarget
    const id = target.itemId

    // did we find it? or was it just a random click?
    if (this.touch.canClick && id === this.find.item) {
      // found it!
      Sounds.found.play(true)

      // no more touchey
      this.clearTouchingTimer()

      // make a duplicate so we can animate it out
      let sprite = target.children[0]
      let found = new PIXI.Sprite(sprite.texture)
      found.anchor.x = found.anchor.y = 0.5
      found.scale.x = found.scale.y = this.scale
      found.x = target.x + (sprite.x * this.scale) + ((sprite.width * this.scale) / 2)
      found.y = target.y + (sprite.y * this.scale) + ((sprite.height * this.scale) / 2)
      this.game.addChild(found)

      anime({
        targets: [found],
        alpha: [1, 0],
        duration: 1500,
        easing: 'easeOutExpo',
        complete: () => {
          anime.remove([found, found.scale])
          this.game.removeChild(found)
        }
      })

      anime({
        targets: [found.scale],
        duration: 1500,
        easing: 'easeOutExpo',
        x: [1 * this.scale, 2 * this.scale],
        y: [1 * this.scale, 2 * this.scale]
      })

      // how much score should we give?
      let score = 1

      // lessen the time
      this.find.timer.wait = clamp(this.find.timer.wait - WAIT_DECREASE, WAIT_MIN, WAIT_INITIAL)

      // next!
      this.updateScore(this.find.score + score)
      this.nextItem()
    }
  }

  clearTouchingTimer = () => {
    if (this.touchingTimer) clearTimeout(this.touchingTimer)
  }

  touchStart = (e) => {
    this.clearTouchingTimer()
    this.touchingTimer = setTimeout(() => {
      this.touching = true
    }, 150)    
  }

  touchEnd = (e) => {
    this.clearTouchingTimer()
    this.touching = false
  }

  touchMove = (e) => {
    if (this.state !== STATES.MAIN) return

    const s = this.sprites.bg
    const sw = s.width * this.gameScale
    const sh = s.height * this.gameScale
    // min max
    const minX = (sw / 2) - (ForceLandscape.width / 2)
    const minY = (sh / 2) - (ForceLandscape.height / 2)
    e.x = clamp(e.x, -minX, minX)
    e.y = clamp(e.y, -minY, minY)

    this.pos.x.to = e.x
    this.pos.y.to = ForceLandscape.portrait ? -e.y : e.y
  }

  getFact = () => {
    let facts = this.data.facts

    // reset used array because we've already used them all
    if (this.facts.used.length >= facts.length) {
      this.facts.used = []
    }

    // select a fact that hasn't been used previously
    let selected = false
    let num = 0
    while (selected === false) {
      num = Math.round(randomIntInRange(0, facts.length - 1))
      if (this.facts.used.indexOf(num) === -1) {
        this.facts.used.push(num)
        selected = num
      }
    }

    let fact = facts[num]
    this.facts.current = num
    return fact
  }

  resize () {
    if (!this.game || !this.sprites.bg) return

    // game scale based on 812 width
    this.gameScale = clamp(ForceLandscape.width / 812, 0.75, 1)

    this.game.scale.x = this.game.scale.y = this.gameScale

    // this.game.scale.x = this.game.scale.y = ResizeService.scale
    this.game.x = (ForceLandscape.width - (this.sprites.bg.width * this.gameScale)) / 2
    this.game.y = (ForceLandscape.height - (this.sprites.bg.height * this.gameScale)) / 2

    // this.timer.base.scale.x = this.timer.base.scale.y = 0.5
    this.timer.base.x = (ResizeService.scale * 40)
    this.timer.base.y = ResizeService.scale * 20

    // this.score.base.scale.x = this.score.base.scale.y = 0.5
    this.score.base.x = this.timer.base.x + this.timer.circle.width - 26
    this.score.base.y = this.timer.base.y - 6

    this.hint.x = ResizeService.scale * 40
    this.hint.y = ForceLandscape.height - (ResizeService.scale * 20) - this.hint.height

    this.close.x = ForceLandscape.width - this.close.width - (ResizeService.scale * 40)
    this.close.y = ResizeService.scale * 20
  }

  update () {
    if (this.find.timer.paused) return

    this.pos.x.process()
    this.pos.y.process()

    const s = this.sprites.bg
    const sw = s.width * this.gameScale
    const sh = s.height * this.gameScale
    if (ForceLandscape.width < sw)
      this.game.x = (ForceLandscape.width / 2) - (sw / 2) - this.pos.x.current
    if (ForceLandscape.height < sh)
      this.game.y = (ForceLandscape.height / 2) - (sh / 2) - this.pos.y.current

    this.updateTimer()
    this.updateHint()

    this.timer.alphaTo.process()
    this.score.alphaTo.process()
    this.hint.alphaTo.process()
    this.close.alphaTo.process()

    let uiAlpha = 0
    if (this.state === STATES.MAIN) {
      uiAlpha = 1
      if (this.touching) uiAlpha = 0.35
      if (this.flashing) uiAlpha = 0
    }

    this.timer.alphaTo.to = this.score.alphaTo.to = uiAlpha
    this.timer.base.alpha = this.timer.alphaTo.current
    this.score.base.alpha = this.timer.alphaTo.current

    this.close.alpha = this.timer.alphaTo.current
    this.close.interactive = uiAlpha === 1

    this.hint.alpha = this.hint.alphaTo.current > 0.98 ? this.timer.alphaTo.current : this.hint.alphaTo.current
  }
}

export default new Play()
