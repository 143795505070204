import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import AnalyticsService from '@/services/AnalyticsService'

// ROUTES
import Loading from '@/views/loading'
import Home from '@/views/home'
import Play from '@/views/play'

// INIT
Vue.use(Router)
const router = new Router({
  base: '/',

  // Modes
  // mode: 'hash', // eg /#/mypage
  // mode: 'history', // eg /mypage (requires htaccess or equivalent for deploy)
  mode: 'abstract', // internal routing, no url change

  // Routes
  routes: [
    {
      path: '/loading',
      name: 'loading',
      component: Loading,
    },
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/play/:scene/:mode',
      name: 'play',
      component: Play
    },
    {
      path: '*',
      redirect: '/'
    }
  ],
})
export default router

// ROUTE GUARD
router.beforeEach((to, from, next) => {
  if (to.name !== 'loading' && !store.state.app.loading.completed) {
    if (typeof to.name === 'string' && to.name !== 'loading') {
      store.commit('app/setRouteAfterLoad', to.path)
    }
    return next({
      name: 'loading',
      replace: true,
    })
  }
  next()
})

// START IF ABSTRACT MODE
if (router.mode === 'abstract') {
  router.replace('/')
}

// AFTER HOOK
router.afterEach((to) => {
  if (to.matched.length > 0) {
    store.commit('app/setRoute', {
      parent: to.matched[0].name,
      child: (to.matched[1] || { name: null }).name,
    })
   AnalyticsService.sendPageview(to.fullPath)
  }
})

window.router = router
