import postscribe from 'postscribe'
import 'url-search-params-polyfill'
import * as analyticsConfig from 'src/config/analytics'

class AnalyticsService {
  constructor() {
    window.dataLayer = window.dataLayer || []
    function gtag() {
      window.dataLayer.push(arguments)
    }
    gtag('js', new Date())
    // gtag('config', analyticsConfig.trackingId, { send_page_view: false })
    gtag('config', analyticsConfig.trackingId)
    this.gtag = gtag
    this.injectScript()
  }

  injectScript() {
    return new Promise((resolve) => {
      const head = document.head || document.getElementsByTagName('head')[0]
      const src = `//www.googletagmanager.com/gtag/js?id=${
        analyticsConfig.trackingId
      }`
      postscribe(head, `<script src='${src}'></script>`, {
        afterAsync: () => {
          resolve()
        },
      })
    })
  }

  sendPageview = (url) => {
    // console.log('sendPageview', url)
    this.gtag('event', 'page_view', {
      page_location: url,
    })
  }

  sendEvent = (category, action, label, value) => {
    this.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value,
    })
  }

  // setDimension = (dimension, value) => {
  //   // console.log('setDimension', dimension, value)
  //   // this.gtag('set', dimension, value)
  // }

  // setSource = (source) => {
  //   // console.log('setSource', source)
  //   // if (!source) return
  //   // this.gtag('set', 'dimension1', source)
  //   // this.sendEvent('source', 'view', source)
  // }
}

export default new AnalyticsService()
