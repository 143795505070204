import SoundService from '@/services/SoundService'

let sounds = {}

SoundService.on(SoundService.Events.COMPLETED, () => {
  sounds = Object.assign(sounds, {
    countdown: SoundService.get(require('@/assets/audio/10SecCountdownTimer.mp3')),
    anp_bgm: SoundService.get(require('@/assets/audio/ANPSceneAudioLoop.mp3')),
    home: SoundService.get(require('@/assets/audio/BackToHomeButton.mp3')),
    close_slide: SoundService.get(require('@/assets/audio/CloseButtonPanelSlide.mp3')),
    count1: SoundService.get(require('@/assets/audio/Count01.mp3')),
    count2: SoundService.get(require('@/assets/audio/Count02.mp3')),
    count3: SoundService.get(require('@/assets/audio/Count03.mp3')),
    countgo: SoundService.get(require('@/assets/audio/CountLetsGo.mp3')),
    cuba_bgm: SoundService.get(require('@/assets/audio/CubaSceneAudioLoop.mp3')),
    found: SoundService.get(require('@/assets/audio/FindPersonPositive.mp3')),
    forest_bgm: SoundService.get(require('@/assets/audio/ForestSceneAudioLoop.mp3')),
    intro_bgm: SoundService.get(require('@/assets/audio/IntroMusic.mp3')),
    next: SoundService.get(require('@/assets/audio/NextButton.mp3')),
    mode_chosen: SoundService.get(require('@/assets/audio/PickGameStyleButton.mp3')),
    rotorua_bgm: SoundService.get(require('@/assets/audio/RotoruaSceneAudioLoop.mp3')),
    select_mode: SoundService.get(require('@/assets/audio/SelectSceneSlideUp.mp3')),
    card_swipe: SoundService.get(require('@/assets/audio/SlideHomePanels.mp3')),
    the_coast_bgm: SoundService.get(require('@/assets/audio/TheCoastSceneAudioLoop.mp3')),
  })

  sounds.anp_bgm.loop = true
  sounds.anp_bgm.volume = 0

  sounds.cuba_bgm.loop = true
  sounds.cuba_bgm.volume = 0

  sounds.forest_bgm.loop = true
  sounds.forest_bgm.volume = 0

  sounds.intro_bgm.loop = true
  sounds.intro_bgm.volume = 0

  sounds.rotorua_bgm.loop = true
  sounds.rotorua_bgm.volume = 0

  sounds.the_coast_bgm.loop = true
  sounds.the_coast_bgm.volume = 0
})

export default sounds