import Raf from '@/helpers/Raf'
import EventEmitter from '@/helpers/EventEmitter'
import ResizeService from '@/services/ResizeService'
import * as PIXI from 'pixi.js'
import { clamp } from '@/helpers/MathUtils'
import ForceLandscape from './helpers/ForceLandscape'

const Events = {
  LOAD_START: 'load_start',
  LOAD_PROGRESS: 'load_progress',
  LOAD_COMPLETE: 'load_complete'
}

// Order matters!
// Will be added to the main stage in this order
const Scenes = {
  background: require('./scenes/background').default,
  home: require('./scenes/home').default,
  play: require('./scenes/play').default,
  countdown: require('./scenes/countdown').default,
  hurry: require('./scenes/hurry').default,
  tutorial: require('./scenes/tutorial').default,
  results: require('./scenes/results').default,
  loader: require('./scenes/loader').default,
  foreground: require('./scenes/foreground').default,
}

class PixiService {
  defaultScene = 'loader' // show this scene first
  currentScene = null
  transition = '' // 'inout'
  transitioning = false
  resolution = ResizeService.isIOS || ResizeService.isAndroid ? clamp(window.devicePixelRatio, 1, 2) : 1
  
  constructor (config) {
    Object.assign(this, EventEmitter)
    this.Events = Events
    this.scenes = Scenes

    // CONFIG
    Object.assign(this, {
      el: document.createElement('canvas'),
      Events: Events,
      loaders: {}
    })

    // init
    this.app = new PIXI.Application({
      width: window.innerWidth,
      height: window.innerHeight,
      view: this.el,
      transparent: true,
      resizeTo: window,
      antialias: true,
      resolution: this.resolution,
      autoDensity: true,
      sharedTicker: true
    })

    // disable shared ticker
    this.ticker = this.app.ticker // PIXI.Ticker.shared
    this.ticker.autoStart = false
    this.ticker.stop()

    this._setupScenes()
    this._addEvents()
    this._resize()
  }
    
  _setupScenes () {
    for (let key in this.scenes) {
      let scene = this.scenes[key]
      scene.key = key
      
      // scene events
      scene.on(scene.Events.LOAD_START, () => {
        this.emit(Events.LOAD_START, key)
      })
      scene.on(scene.Events.LOAD_PROGRESS, (data) => {
        this.emit(Events.LOAD_PROGRESS, data)
      })
      scene.on(scene.Events.LOAD_COMPLETE, () => {
        this.emit(Events.LOAD_COMPLETE, key)
      })
      
      // add each to main stage
      this.app.stage.addChild(scene.stage)
      
      // if first scene, make current and load
      if (this.defaultScene === key) {
        this.currentScene = key
        scene.enter()
      }
      
      // if 'alwaysShown' is set, show it
      if (scene.alwaysShown) scene.enter()
    }
  }

  _addEvents () {
    ResizeService.on(ResizeService.Events.RESIZE, this._resize)
    Raf.add(this._render)
  }

  _removeEvents () {
    ResizeService.off(ResizeService.Events.RESIZE, this._resize)
    Raf.remove(this._render)
  }
  
  _resize = () => {
    this.app.resize()
  }

  _render = (delta) => {
    let time = performance.now()
    
    for (let key in this.scenes) {
      let scene = this.scenes[key]
      if (scene.active) scene.update()
    }
    
    // force landscape for portrait?
    this.app.stage.angle = ForceLandscape.portrait ? 90 : 0
    this.app.stage.x = ForceLandscape.portrait ? ForceLandscape.height : 0
    
    this.ticker.update(time)
    // this.app.render(this.app.stage)
  }
  
  set scene (id) {
    if (this.scenes[id]) { // && !this.transitioning
      if (this.transition === 'inout') {
        // in-out transition
        let prev = this.scenes[this.currentScene]
        let next = this.scenes[id]
        this.transitioning = true
        prev.leave().then(() => {
          next.enter().then(() => {
            console.log('next is in')
            this.transitioning = false
          })
        })
      } else {
        // cross transition
        let prev = this.scenes[this.currentScene]
        let next = this.scenes[id]
        if (prev !== next) prev.leave()
        next.enter()
      }
      
      this.currentScene = id
    }
  }
  
  get scene () {
    return this.scenes[this.currentScene]
  }

  destroy () {
    this._removeEvents()
  }

  getDomElement = () => {
    return this.el
  }
  
  getScene = (id) => {
    if (this.scenes[id]) return this.scenes[id]
    return null
  }
}

export default new PixiService()
