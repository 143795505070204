import EventEmitter from 'src/helpers/EventEmitter'
import Queue from 'src/helpers/Queue'
import AudioTrack from './tracks/Howler'
// import AudioTrack from './tracks/HTML5'
// import AudioTrack from './tracks/WebAudio'

const Events = {
  GROUP_PROGRESS: 'groupProgress',
  GROUP_COMPLETED: 'groupCompleted',
  PROGRESS: 'progress',
  COMPLETED: 'completed',
  UNLOCKED: 'unlocked'
}

class Group {
  constructor(id) {
    Object.assign(this, {
      id: id,
      sounds: [],
      progress: 0, // 0 to 1
      completed: false,
    })
  }
}

class Sound {
  constructor(url, groupId, config) {
    Object.assign(this, {
      url: url,
      groupId: groupId,
      config: config,
      bytesLoaded: 0,
      bytesTotal: 1,
      progress: 0, // 0 to 1
      completed: false,
      callback: null,
    })
  }
}

class SoundService {
  constructor() {
    Object.assign(this, EventEmitter)
    this.concurrency = 2

    // PROGRESS
    this._progress = 0
    this._completed = false

    // mobile audio activation
    this.mobileActivate = true
    this._unlocked = false

    // GROUPS AND SOUNDS
    this.groups = []
    this.sounds = []
  }

  get completed () {
    return this._completed
  }

  get unlocked () {
    return this._unlocked
  }

  _onLoad = (data) => {
    let sound = this.sounds.filter((sound) => {
      return sound.url === data.url
    })[0]

    sound.progress = 1
    sound.completed = true
    sound.obj = data.obj

    this._updateGroupProgress()
    this._updateTotalProgress()

    if (typeof sound.callback === 'function') sound.callback()
  }

  _updateGroupProgress = () => {
    for (const group of this.groups) {
      let progress = 0
      for (const sound of group.sounds) {
        progress += sound.progress
      }
      progress /= group.soundsTotal

      if (group.progress !== progress && !group.completed) {
        this.emit(Events.GROUP_PROGRESS, {
          groupId: group.id,
          progress: progress,
        })
        if (progress >= 1 && !group.completed) {
          group.completed = true
          this.emit(Events.GROUP_COMPLETED, { groupId: group.id })
        }
      }
      group.progress = Math.min(progress, 1)
    }
  }

  _updateTotalProgress = () => {
    let progress = 0
    for (const sound of this.sounds) {
      progress += sound.progress
    }
    progress /= this.sounds.length

    if (this.sounds.length === 0) progress = 1

    if (this._progress !== progress) {
      this.emit(Events.PROGRESS, progress)
      if (progress >= 1) {
        this._completed = true
        this._onTotalComplete()
        this.emit(Events.COMPLETED)
      }
    }
    this._progress = Math.min(progress, 1)
  }

  _loadSound = (sound) => {
    return new Promise((resolve) => {
      let obj = new AudioTrack(sound.url, null, sound.config)
      obj.load().then(() => {
        this._onLoad({
          url: sound.url,
          obj: obj
        })
        resolve()
      })
    })
  }

  _loadSounds = (sounds) => {
    return new Promise((resolve) => {
      let q = new Queue(this.concurrency)
      for (let sound of sounds) {
        q.add(() => {
          return this._loadSound(sound)
        })
      }
      if (q.pending === 0) resolve()
      q.on(Queue.Events.EMPTY, () => {
        resolve()
      })
    })
  }

  _unlockAudio = () => {
    if (!this._unlocked) {
      Object.keys(this.sounds).forEach(sound => {
        let s = this.sounds[sound]
        s.volume = 1
        s.muted = false
      })

      document.body.removeEventListener('click', this._unlockAudio) // android
      document.body.removeEventListener('touchstart', this._unlockAudio) // ios

      this._unlocked = true

      this.emit(Events.UNLOCKED)
    }
  }

  load = (manifest, loadGroup = null, concurrency = 2) => {
    this.concurrency = concurrency

    // CREATE SOUNDS & GROUPS
    for (const config of manifest) {
      // only load specific group at a time?
      if (loadGroup && loadGroup !== config.id) {
        // console.log(`Skipping load of sound group: ${config.id}`)
        continue
      }

      // check if group exists
      let group = this.groups.filter((g) => {
        return g.id === config.id
      })[0]
      // if yes and group load completed, exit
      if (group && group.completed) {
        // console.log(`Sound group already loaded, skipping: ${config.id}`)
        this.emit(Events.GROUP_COMPLETED, { groupId: group.id })
        this.emit(Events.COMPLETED)
        return
      } else if (!group) {
        // nope, new group
        group = new Group(config.id)
        this.groups.push(group)
      }

      for (let url of config.urls) {
        // Check if exists
        let u = typeof url === 'string' ? url : url.url
        let c = typeof url === 'object' ? url : null
        let sound = this.sounds.filter((sound) => {
          return sound.url === u
        })[0]
        if (sound) continue

        // Create sound
        sound = new Sound(u, group.id, c)
        this.sounds.push(sound)
        group.sounds.push(sound)
      }

      group.soundsTotal = group.sounds.length
    }

    // LOAD
    return new Promise((resolve) => {
      let q = new Queue(1) // Load only 1 group at a time
      for (const config of manifest) {
        let sounds = this.sounds.filter((sound) => {
          return sound.groupId === config.id
        })
        q.add(() => {
          return this._loadSounds(sounds)
        })
      }

      this._loadResolve = resolve

      if (q.pending === 0) this._onTotalComplete()
      q.on(Queue.Events.EMPTY, this._onTotalComplete)
    })
  }

  _onTotalComplete = () => {
    // this._updateGroupProgress()
    // this._updateTotalProgress()

    if (!this._completed) return

    // console.log(`SoundService loaded`)

    // mobile audio unlock
    if (this.mobileActivate && !this._unlocked) {
      document.body.addEventListener('click', this._unlockAudio) // android
      document.body.addEventListener('touchstart', this._unlockAudio) // ios
    }

    this._loadResolve()
  }

  get = (url) => {
    let sound = this.sounds.filter((sound) => {
      return sound.url === url
    })[0]

    if (sound && sound.obj){return sound.obj}
    return null
  }

  getGroup = (id) => {
    let group = this.groups.filter((g) => {
      return g.id === id
    })[0]
    return group
  }
}

let service = new SoundService()
service.Events = Events
window.SoundService = service
export default service
