export default [
	{
		id: 1,
		layers: [
			{
				id: 'bg',
				url: require('@/assets/img/play/tutorial/1/bg.png')
			},
			{
				id: 'action',
				url: require('@/assets/img/play/tutorial/1/move.png'),
				x: 180,
				y: 145
			},
			{
				id: 'text',
				text: 'Drag the map',
				style: {
					fontFamily: 'Fenway Park JF',
					fontSize: 44,
					fill: 0xe6754b,
					padding: 7,
					trim: true
				},
				x: 105,
				y: 290
			}
		]
	},
	{
		id: 2,
		layers: [
			{
				id: 'bg',
				url: require('@/assets/img/play/tutorial/2/bg.png')
			},
			{
				id: 'action',
				url: require('@/assets/img/play/tutorial/2/find.png'),
				x: 320,
				y: 122
			},
			{
				id: 'text',
				text: 'Find the character',
				style: {
					fontFamily: 'Fenway Park JF',
					fontSize: 44,
					fill: 0xe6754b,
					padding: 7,
					trim: true
				},
				x: 75,
				y: 290
			}
		]
	},
	{
		id: 3,
		layers: [
			{
				id: 'bg',
				url: require('@/assets/img/play/tutorial/3/bg.png')
			},
			{
				id: 'action',
				url: require('@/assets/img/play/tutorial/3/clock.png'),
				x: 8,
				y: 120
			},
			{
				id: 'text',
				text: 'Beat the clock',
				style: {
					fontFamily: 'Fenway Park JF',
					fontSize: 44,
					fill: 0xe6754b,
					padding: 7,
					trim: true
				},
				x: 105,
				y: 290
			}
		]
	},
]