export default class Lerp {
  constructor ({ current = 0, to = 0, ease = 0.15 } = {}) {
    this.current = current
    this.to = to
    this.ease = ease
  }

  process = () => {
    // this.current += (this.to - this.current) * this.ease
    let c = this.current + ((this.to - this.current) * this.ease)
    this.current = Math.round(c * 1000) / 1000
  }

  set = () => {
    this.current = this.to
  }
}
