export default [
  // {
  //   name: 'MStiffHeiPRC',
  //   url: require('assets/fonts/MStiffHeiPRC-UltraBold.subset.ttf'),
  //   options: {
  //     weight: 'bold',
  //   },
  // },
  {
    name: 'Dirk Black',
    options: {},
  },
  {
    name: 'Avenir Black',
    options: {},
  },
  {
    name: 'Avenir Heavy',
    options: {},
  },
  {
    name: 'Fenway Park JF',
    options: {},
  },
]
