import * as PIXI from 'pixi.js'

export default (el, px, py) => {
	let c = new PIXI.Container()
	c.addChild(el)

	let p = new PIXI.Graphics()
	p.beginFill(0xffffff)
	p.drawRect(0, 0, el.width + (px * 2), el.height + (py * 2))
	p.endFill()
	p.alpha = 0
	c.addChild(p)

	el.x = px
	el.y = py

	return c
}