import * as mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'
import 'url-search-params-polyfill'
// import pack from '!!json-loader!src/../package.json?limit=1'

// URL PARAMS
let urlParams = new window.URLSearchParams(window.location.search)

// DEBUG
let debug = process.env.NODE_ENV !== 'production'
// debug = false
if (urlParams.get('_debug') !== null) debug = true
if (urlParams.get('_debug') === 'false') debug = false

// VCONSOLE
let vconsole = process.env.NODE_ENV !== 'production'
vconsole = false
if (urlParams.get('_vconsole') !== null) vconsole = true
if (urlParams.get('_vconsole') === 'false') vconsole = false

// STATE
const state = {
  debug: debug,
  vconsole: vconsole,

  // Version
  version: {
    build: 1000,
    date: null,
  },

  // LOADING
  loading: {
    progress: 0,
    step: null,
    completed: false,
  },
  
  // PIXI LOADER
  pixiLoading: {
    progress: 1,
    completed: true
  },

  // ROUTE
  route: {
    afterLoad: '/', // default page to show after loading
    curr: {
      parent: null,
      child: null,
    },
    prev: {
      parent: null,
      child: null,
    },
  },

  selectCurrent: 0,
  
  score: 0
}

// EXPORT
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
