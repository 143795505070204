import Scene from './Scene'
import * as PIXI from 'pixi.js'
import { coverScale } from '@/helpers/Scale'
import ForceLandscape from '../helpers/ForceLandscape'

class Background extends Scene {
  count = 0
  
  constructor () {
    const assets = [
      // {
      //   id: 'bg',
      //   url: require('@/assets/img/scenes/background/1.jpg')
      // }
    ]
    
    super(assets)
    
    // always show
    this.alwaysShown = true
    // no loader events
    this.emitEvents = false
  }
  
  setup () {
    this.bg = new PIXI.Graphics()
    this.stage.addChild(this.bg)
    this.draw()
    
    // // bg
    // let bg = new PIXI.Sprite(this.loader.resources.bg.texture)    
    // this.sprites.bg = bg      
    // this.stage.addChild(this.sprites.bg)
    
    // let dis = new PIXI.Sprite.from(require('@/assets/img/displacement.jpg'))
    // dis.texture.baseTexture.wrapMode = PIXI.WRAP_MODES.REPEAT
    // let disFilter = new PIXI.filters.DisplacementFilter(dis)
    // dis.scale.y = 1.5
    // dis.scale.x = 1.5
    // this.dis = dis
    // 
    // this.stage.addChild(dis)
    // this.stage.filters = [
    //   disFilter,
    //   // smooth out rough edges?
    //   // new PIXI.filters.BlurFilter(0.5, 1, 1)
    // ]
  }
  
  resize () {
    this.draw()
    
    // // cover scale
    // let bg = this.sprites.bg
    // let scale = coverScale({
    //   width: bg.texture.width,
    //   height: bg.texture.height
    // })
    // 
    // bg.x = scale.x
    // bg.y = scale.y
    // bg.scale.x = bg.scale.y = scale.scale
  }
  
  draw () {
    this.bg.clear()
    this.bg.beginFill(0xe6754b)
    this.bg.drawRect(0, 0, ForceLandscape.width, ForceLandscape.height)
    this.bg.endFill()
  }
  
  update () {
    // this.dis.x = this.count * 10
    // this.dis.y = this.count * 10
    // this.count += 0.05
  }
}

export default new Background()
