<template lang="pug">
#loading
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import Lerp from '@/helpers/Lerp'
import Raf from '@/helpers/Raf'
import PixiService from '@/services/PixiService'
// import AssetService from '@/services/AssetService'
import SoundService from '@/services/SoundService'

export default {
  name: 'loading',
  data() {
    return {
      firstLoad: true,
      progress: {
        pixi: 0,
        // assets: 0,
        sounds: 0,
        visual: new Lerp({ ease: 0.07 }),
        shown: 0
      },
      timer: {
        last: Date.now(),
        wait: 4000
      }
    }
  },
  computed: {
    ...mapState('app', [
      'loading', 
      'route'
    ]),
  },
  mounted() {
    if (this.firstLoad) this.timer.last = Date.now()

    PixiService.on(PixiService.Events.LOAD_PROGRESS, this.onPixiProgress)
    // AssetService.on(AssetService.Events.PROGRESS, this.onAssetsProgress)
    SoundService.on(SoundService.Events.PROGRESS, this.onSoundsProgress)
    Raf.add(this.update)
  },
  beforeDestroy() {
    PixiService.off(PixiService.Events.LOAD_PROGRESS, this.onPixiProgress)
    // AssetService.off(AssetService.Events.PROGRESS, this.onAssetsProgress)
    SoundService.off(SoundService.Events.PROGRESS, this.onSoundsProgress)
    Raf.remove(this.update)
  },
  methods: {
    ...mapMutations('app', ['setLoadProgress']),
    onPixiProgress(data) {
      this.progress.pixi = data.progress
    },
    // onAssetsProgress(data) {
    //   this.progress.assets = data
    // },
    onSoundsProgress(data) {
      this.progress.sounds = data
    },
    update() {
      let progress = 0
      let loaders = 2
      
      progress += this.progress.pixi / loaders
      // progress += this.progress.assets / loaders
      progress += this.progress.sounds / loaders

      this.progress.visual.to = progress
      this.progress.visual.process()
      
      let visual = Math.round(this.progress.visual.current * 1000) / 1000
      this.progress.shown = visual
      PixiService.getScene('loader').progress = visual

      this.setLoadProgress(progress)

      if (
        this.loading.completed && 
        visual >= 0.99 &&
        Date.now() - this.timer.last > this.timer.wait
      ) {
        this.next()
      }
    },
    next() {
      this.progress.shown = 1
      Raf.remove(this.update)
      this.setLoadProgress(1)

      // on delay so you see 100%
      setTimeout(() => {
        this.$router.replace(this.route.afterLoad)
      }, 250)
    },
  },
}
</script>

<style lang="sass" scoped>
@import "src/styles/common.sass"

#loading
  +full()
  
  p
    width: 100%
    text-align: center
    position: absolute
    left: 0
    top: calc(50% + 10rem)
    font-size: 2.5rem
    color: #fff
    font-weight: bold
</style>
