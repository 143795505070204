class Raf {
  constructor() {
    this.running = false
    this.rafId = null
    this._fns = []
  }

  _update = (delta) => {
    let i = this._fns.length
    while (i--) {
      let fn = this._fns[i]
      if (fn) this._fns[i](delta)
    }
    this.rafId = window.requestAnimationFrame(this._update)
  }

  _updateWithStats = (delta) => {
    this.stats.begin()
    let i = this._fns.length
    while (i--) {
      let fn = this._fns[i]
      if (fn) this._fns[i](delta)
    }
    this.stats.end()
    this.rafId = window.requestAnimationFrame(this._updateWithStats)
  }

  start = () => {
    if (this.running) return
    this.running = true
    this.rafId = window.requestAnimationFrame(this._update)
  }
  
  async startWithStats () {
    const Stats = await import('stats.js')
    
    this.stats = new Stats.default()
    this.stats.showPanel(0) // 0: fps, 1: ms, 2: mb, 3+: custom
    this.stats.dom.id = 'stats'
    document.querySelector('html').appendChild(this.stats.dom)
    
    if (this.running) return
    this.running = true
    this.rafId = window.requestAnimationFrame(this._updateWithStats)
  }

  stop = () => {
    window.cancelAnimationFrame(this.rafId)
    this.running = false
  }

  add = (fn) => {
    if (typeof fn === 'function') {
      if (this._fns.indexOf(fn) >= 0) return false
      this._fns.push(fn)
      return true
    } else {
      return false
    }
  }

  remove = (fn) => {
    const idx = this._fns.indexOf(fn)
    if (idx >= 0) this._fns.splice(idx, 1)
  }
}

const raf = new Raf()
window.Raf = raf
export default raf
