// import UserInfo from 'src/libs/userinfo.min'

// export const getCountry = function({ commit }) {
//   return new Promise(resolve => {
//     UserInfo.getInfo(data => {
//       // console.log('getCountry', data)
//       commit('setCountry', data.country.code)
//       resolve()
//     })
//   })
// }

export const getCountry = function({ commit }) {
  // return new Promise(resolve => {
  return fetch('https://api.ipdata.co/')
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      commit('setCountry', json.country_code)
    })
  // })
}
