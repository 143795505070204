export const getVersion = function({ commit }) {
  return new Promise( (resolve, reject) => {

      try {
          const buildTag = document.querySelector('meta[name="resn:build"]');
          const buildDateTag = document.querySelector('meta[name="resn:build-date"]');

          if( buildDateTag && buildTag ) {
              commit('setVersion', {
                  build: buildTag.getAttribute('content'),
                  date: buildDateTag.getAttribute('content')
              })
          }

          resolve();

      } catch( err ) {
          reject('No version info found');
      }
  })
};
