import Scene from './Scene'
import * as PIXI from 'pixi.js'
import { curvyCircle } from '@/helpers/Drawing'
import anime from 'animejs'
import ForceLandscape from '../helpers/ForceLandscape'
import router from '@/router'
import store from '@/store'
import FontService from '@/services/FontService'
import Sounds from '@/services/PixiService/Sounds'
import PixiService from '@/services/PixiService'

class Results extends Scene {
  card = new PIXI.Container()

  constructor () {
    const assets = [
      {
        id: 'border-vertical',
        url: require('@/assets/img/results/border-vertical.png')
      },
      {
        id: 'border-horizontal',
        url: require('@/assets/img/results/border-horizontal.png')
      },
      {
        id: 'replay',
        url: require('@/assets/img/results/replay.png')
      },
      {
        id: 'select',
        url: require('@/assets/img/results/select.png')
      }
    ]
    
    super(assets)
  }
  
  setup () {
    if (FontService.loaded) this.makeThings()
    else FontService.on(FontService.Events.LOADED, this.makeThings)  
  }

  makeThings = () => {
    this.createBg()
    this.createFeature()
    this.createScore()
    this.createButtons()
    this.createBorders()
    this.resize()

    this.stage.addChild(this.card)
  }
  
  shown () {
    this.updateScore()
    this.updateFeature()

    Sounds.close_slide.play(true)
  }
  
  resize () {
    this.stage.x = (ForceLandscape.width / 2) - (this.stage.width / 2)
    this.stage.y = (ForceLandscape.height / 2) - (this.stage.height / 2)

    this.resizeFeature()
    this.updateScore()
  }

  resizeFeature () {
    let { base, title, body, body2 } = this.feature
    let h = 0
    body.y = title.height + 20
    h += body.y
    body2.y = body.y + body.height + 15
    h += body.height + 15 + body2.height
    base.y = (this.card.height / 2) - (h / 2)
  }
  
  updateScore () {
    let score = this.score
    score.amount.text = store.state.app.score
    score.amount.x = score.title.x = 0
    score.title.x = (score.base.width / 2) - (score.title.width / 2)
    score.amount.x = (score.base.width / 2) - (score.amount.width / 2)
    score.base.x = this.card.width - 105 - (score.base.width / 2)
  }

  updateFeature () {
    // get a random fact from play scene data
    let fact = PixiService.getScene('play').getFact()

    let { base, title, body, body2 } = this.feature
    title.text = fact.title
    body.text = fact.english
    body2.text = fact.tereo

    this.resizeFeature()
    setTimeout(() => {
      this.resizeFeature()
    }, 150)
    setTimeout(() => {
      this.resizeFeature()
    }, 400)
  }
  
  createBg () {
    let bg = new PIXI.Graphics()
    bg.beginFill(0xffffd6)
    bg.drawRoundedRect(0, 0, 514, 280, 10)
    bg.endFill()
    this.card.addChild(bg)
  }
  
  createFeature () {
    let feature = {
      base: new PIXI.Container()
    }
    
    let title = new PIXI.Text('The Giant Kauri', {
      fontFamily: 'Fenway Park JF',
      fontSize: 34,
      fill: 0xe6754b,
      wordWrap: true,
      wordWrapWidth: 265,
      padding: 7,
      trim: true
    })
    feature.title = title
    feature.base.addChild(title)
    
    let body = new PIXI.Text('Tāne Mahuta, the giant Kauri tree is estimated to be between 1,250 and 2,500 years old!', {
      fontFamily: 'Avenir Heavy',
      fontSize: 15,
      fill: 0xe6754b,
      wordWrap: true,
      wordWrapWidth: 265,
      trim: true
    })
    feature.body = body
    feature.base.addChild(body)
    
    let body2 = new PIXI.Text('Ko Tāne Mahuta, ko te kauri nui o Kauri kei waenganui i te 1,250 me te 2,500 tau!', {
      fontFamily: 'Avenir Heavy',
      fontSize: 15,
      fill: 0x54a05e,
      wordWrap: true,
      wordWrapWidth: 265,
      trim: true
    })
    feature.body2 = body2
    feature.base.addChild(body2)

    feature.base.x = 25
    
    this.feature = feature
    this.card.addChild(feature.base)
  }
  
  createScore () {
    let score = {
      base: new PIXI.Container()
    }
    
    let title = new PIXI.Text('YOUR SCORE', {
      fontFamily: 'Avenir Black',
      fontSize: 13,
      fill: 0xe6754b,
      trim: true
    })
    score.title = title
    score.base.addChild(title)
        
    let amount = new PIXI.Text('0', {
      fontFamily: 'Dirk Black',
      fontSize: 84,
      fill: 0xe6754b,
      trim: true
    })
    amount.y = title.height + 15
    score.amount = amount
    score.base.addChild(amount)    

    score.base.y = 42
    
    this.score = score
    this.card.addChild(score.base)
  }

  createButtons () {
    let buttons = {
      base: new PIXI.Container()
    }

    let replay = new PIXI.Container()
    let rbg = new PIXI.Graphics()
    rbg.beginFill(0xe3ce97)
    rbg.drawCircle(35, 35, 35)
    rbg.endFill()
    replay.addChild(rbg)
    let rs = new PIXI.Sprite(this.loader.resources.replay.texture)
    rs.anchor.x = rs.anchor.y = 0.5
    rs.scale.x = rs.scale.y = 0.5
    rs.x = rbg.width / 2
    rs.y = rbg.height / 2
    replay.addChild(rs)
    replay.interactive = true
    replay.cursor = 'pointer'
    replay.on('click', this.onReplayClick)
    replay.on('tap', this.onReplayClick)
    buttons.replay = replay
    buttons.base.addChild(replay)
    
    let select = new PIXI.Container()
    let sbg = new PIXI.Graphics()
    sbg.beginFill(0x54a05e)
    sbg.drawCircle(35, 35, 35)
    sbg.endFill()
    select.addChild(sbg)
    let ss = new PIXI.Sprite(this.loader.resources.select.texture)
    ss.anchor.x = ss.anchor.y = 0.5
    ss.scale.x = ss.scale.y = 0.5
    ss.x = sbg.width / 2
    ss.y = sbg.height / 2
    select.addChild(ss)
    select.interactive = true
    select.cursor = 'pointer'
    select.on('click', this.onSelectClick)
    select.on('tap', this.onSelectClick)
    buttons.select = select
    buttons.base.addChild(select)

    select.x = replay.width + 16

    buttons.base.x = this.card.width - buttons.base.width - 25
    buttons.base.y = 180

    this.buttons = buttons
    this.card.addChild(buttons.base)
  }

  createBorders () {
    let v = new PIXI.Sprite(this.loader.resources['border-vertical'].texture)
    v.scale.x = v.scale.y = 0.5
    v.anchor.x = v.anchor.y = 0.5
    v.x = this.card.width - 202
    v.y = this.card.height / 2
    this.card.addChild(v)

    let h = new PIXI.Sprite(this.loader.resources['border-horizontal'].texture)
    h.scale.x = h.scale.y = 0.5
    h.anchor.y = 0.5
    h.x = v.x + 10
    h.y = 156
    this.card.addChild(h)
  }
  
  onReplayClick = (e) => {
    Sounds.home.play(true)
    this.closeAction = 'replay'
    this.leave()
  }
  
  onSelectClick = async (e) => {
    Sounds.home.play(true)
    this.closeAction = 'select'
    await this.leave()
    router.push('/')
  }

  fadein (callback) {
    let x = (ForceLandscape.width / 2) - (this.stage.width / 2)
    let y = (ForceLandscape.height / 2) - (this.stage.height / 2)

    this.active = true
    this.closeAction = null
    anime.remove(this.stage)      
    anime({
      targets: this.stage,
      alpha: [0, 1],
      y: [y + (this.stage.height * 0.5), y],
      duration: 750,
      complete: () => {
        if (callback) callback()
      }
    })
  }
  
  fadeout (callback) {
    let x = (ForceLandscape.width / 2) - (this.stage.width / 2)
    let y = (ForceLandscape.height / 2) - (this.stage.height / 2)

    anime.remove(this.stage)
    anime({
      targets: this.stage,
      alpha: [1, 0],
      y: [y, y - (this.stage.height * 0.5)],
      duration: 750,
      easing: 'easeOutExpo',
      complete: () => {
        this.active = false
        if (callback) callback()
      }
    })
  }  
}

export default new Results()
