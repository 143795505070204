/*
// start the sound loading like this.
// this loads the "main" group by default.
SoundService.load(soundManifest)

// specify a different group:
SoundService.load(soundManifest, 'intro')

// get a sound
let path = require('@/assets/audio/pressandhold_in_96k.mp3')
let bloop = SoundService.get(path)

// play a sound
bloop.play()
*/

export default [
  // main group
  {
    id: 'main',
    urls: [
      // {
      //   url: require('@/assets/audio/ANPSceneAudioLoop.mp3'),
      //   loopStart: 0,
      //   loopEnd: 0
      // },
      require('@/assets/audio/10SecCountdownTimer.mp3'),
      require('@/assets/audio/ANPSceneAudioLoop.mp3'),
      require('@/assets/audio/BackToHomeButton.mp3'),
      require('@/assets/audio/CloseButtonPanelSlide.mp3'),
      require('@/assets/audio/Count01.mp3'),
      require('@/assets/audio/Count02.mp3'),
      require('@/assets/audio/Count03.mp3'),
      require('@/assets/audio/CountLetsGo.mp3'),
      require('@/assets/audio/CubaSceneAudioLoop.mp3'),
      require('@/assets/audio/FindPersonPositive.mp3'),
      require('@/assets/audio/ForestSceneAudioLoop.mp3'),
      require('@/assets/audio/IntroMusic.mp3'),
      require('@/assets/audio/NextButton.mp3'),
      require('@/assets/audio/PickGameStyleButton.mp3'),
      require('@/assets/audio/RotoruaSceneAudioLoop.mp3'),
      require('@/assets/audio/SelectSceneSlideUp.mp3'),
      require('@/assets/audio/SlideHomePanels.mp3'),
      require('@/assets/audio/TheCoastSceneAudioLoop.mp3')
    ]
  }
]
