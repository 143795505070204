import Scene from './Scene'
import * as PIXI from 'pixi.js'
import Lerp from '@/helpers/Lerp'
import Touch from '../helpers/Touch'
import { clamp, toRadians, randomIntInRange } from '@/helpers/MathUtils'
import tutorial from '../manifests/Tutorial'
import anime from 'animejs'
import ForceLandscape from '../helpers/ForceLandscape'
import PixiService from '@/services/PixiService'
import FontService from '@/services/FontService'
import Padded from '../helpers/Padded'
import Sounds from '@/services/PixiService/Sounds'

class Countdown extends Scene {    
  constructor () {
    const assets = []
    
    super(assets)
  }

  setup () {
    if (FontService.loaded) this.makeThings()
    else FontService.on(FontService.Events.LOADED, this.makeThings)      
  }

  makeThings = () => {
    let ti = {
      base: new PIXI.Container(),
      timeline: anime.timeline({ autoplay: false }),
      bg: new PIXI.Graphics(),
      texts: []
    }

    // add bg to base
    ti.timeline.add({
      targets: ti.bg,
      alpha: [0, 0.5],
      duration: 150,
      easing: 'easeOutSine',
      offset: 0
    })
    ti.timeline.add({
      targets: ti.bg,
      alpha: [0.5, 0],
      duration: 3000,
      easing: 'linear',
      offset: 150
    })
    ti.base.addChild(ti.bg)

    // text styles
    let bigStyle = {
      fontFamily: 'Dirk Black',
      fontSize: 140,
      fill: 0xffffd5,
      trim: true,
      letterSpacing: 2
    }
    let medStyle = Object.assign({}, bigStyle, { fontSize: 80 })

    // text lines
    let texts = [
      { text: '3', style: bigStyle, sound: Sounds.count1 },
      { text: '2', style: bigStyle, sound: Sounds.count2 },
      { text: '1', style: bigStyle, sound: Sounds.count3 },
      { text: `LET'S GO!`, style: medStyle, sound: Sounds.countgo },
    ]

    // make text layers
    texts.forEach((ts, index) => {
      let t = new PIXI.Text(ts.text, ts.style)
      t.anchor.set(0.5, 0.5)
      ti.base.addChild(t)
      ti.texts.push(t)

      let o = (1000 * index) + 150

      // appear
      let appear = {
        easing: 'easeOutExpo',
        duration: 200,
        offset: o
      }
      ti.timeline.add(Object.assign({}, appear, {
        targets: t,
        alpha: [0, 1],
        begin: () => {
          ts.sound.play()
        }
      }))
      ti.timeline.add(Object.assign({}, appear, {
        targets: t.scale,
        x: [0.75, 0.95],
        y: [0.75, 0.95],
      }))

      // slight scale
      let slight = {
        easing: 'linear',
        duration: 600,
        offset: o + 200
      }
      ti.timeline.add(Object.assign({}, slight, {
        targets: t.scale,
        x: [0.95, 1],
        y: [0.95, 1],
      }))

      // disappear
      let disappear = {
        easing: 'easeOutExpo',
        duration: 200,
        offset: o + 800
      }
      ti.timeline.add(Object.assign({}, disappear, {
        targets: t,
        alpha: [1, 0],
      }))
      ti.timeline.add(Object.assign({}, disappear, {
        targets: t.scale,
        x: [1, 0.75],
        y: [1, 0.75],
      }))
    })

    this.stage.addChild(ti.base)
    this.timedIntro = ti
  }
  
  shown () {
    this.stage.alpha = 1
  }

  fadein (callback) {
    this.active = true
    
    let tl = this.timedIntro.timeline
    tl.complete = () => {
      callback()
    }
    tl.restart()
  }

  fadeout (callback) {
    this.active = false
    callback()
  }

  resize () {
    this.stage.x = this.stage.y = 0

    // timed intro resize
    let ti = this.timedIntro
    ti.base.x = ti.base.y = 0
    ti.bg.x = ti.bg.y = 0
    ti.bg.clear()
    ti.bg.beginFill(0x000000)
    ti.bg.drawRect(0, 0, ForceLandscape.width, ForceLandscape.height)
    ti.bg.endFill()
    ti.texts.forEach(t => {
      t.x = ForceLandscape.width / 2
      t.y = ForceLandscape.height / 2
    })
  }
  
  update () {
    // visible?
    let play = PixiService.getScene('play')
    this.timedIntro.base.visible = play.mode === play.MODES.TIMED
  }
}

export default new Countdown()
