// ********************************************************
//
// IMPORT
//
// ********************************************************
import FontService from '@/services/FontService'
// import WechatService from '@/services/WechatService'
// import AssetService from '@/services/AssetService'
import SoundService from '@/services/SoundService'
import PixiService from '@/services/PixiService'
import '@/services/ResizeService'
import '@/services/AnalyticsService'
import Raf from '@/helpers/Raf'

import fontManifest from '@/config/fonts'
// import assetManifest from '@/config/asset'
import soundManifest from '@/config/sound'

import store from '@/store'

// ********************************************************
//
// DOCUMENT
//
// ********************************************************
const loadDocument = function() {
  return new Promise((resolve) => {
    const onReady = function() {
      setTimeout(resolve, 1)
    }

    if (document.readyState === 'complete') {
      onReady()
    } else {
      document.onreadystatechange = () => {
        if (document.readyState === 'complete') {
          onReady()
        }
      }
    }
  })
}

const removeInitialLoader = function() {
  var preload = document.querySelector('.preload')
  while (preload.firstChild) {
    preload.removeChild(preload.firstChild)
  }
  preload.parentNode.removeChild(preload)
  // set bg to black
  document.body.style.background = '#000'
}

// ********************************************************
//
// APP
//
// ********************************************************
async function initApp() {
  await loadDocument()

  console.group('Initialization')

  store.commit('app/setLoadStep', 'vconsole')
  if (store.state.app.vconsole) {
    const VConsole = await import(/* webpackChunkName: "vconsole" */'vconsole')
    new VConsole.default()
  }
  
  store.commit('app/setLoadStep', 'raf')
  if (process.env.NODE_ENV === 'production')
    Raf.start()
  else
    await Raf.startWithStats()
    
  store.commit('app/setLoadStep', 'pixi-loader')
  await PixiService.getScene('loader').load()

  store.commit('app/setLoadStep', 'app')
  await import('./main')

  store.commit('app/setLoadStep', 'fonts')
  await FontService.load(fontManifest)

  // store.commit('app/setLoadStep', 'wechat')
  // await WechatService.init()
  
  store.commit('app/setLoadStep', 'pixi')
  await PixiService.getScene('home').load()

  store.commit('app/setLoadStep', 'sound')
  await SoundService.load(soundManifest)

  removeInitialLoader()

  // store.commit('app/setLoadStep', 'assets')
  // await AssetService.load(assetManifest, 2, 'main')

  store.commit('app/setLoadStep', 'completed')
  store.commit('app/setLoadComplete')

  console.groupEnd()

  PixiService.getScene('tutorial').load()
  PixiService.getScene('results').load()

  store.dispatch('app/getVersion')
}

// ********************************************************
//
// INIT
//
// ********************************************************
initApp()
