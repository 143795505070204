import EventEmitter from 'src/helpers/EventEmitter'
import { clamp, toRadians, randomIntInRange } from '@/helpers/MathUtils'
import ForceLandscape from './ForceLandscape'

export const EVENTS = {
  START: 'start',
  MOVE: 'move',
  END: 'end'
}

export default class Touch {
  _touch = {}
  
  constructor (el, options = {}) {
    if (!el) return console.warn('Cannot create touch helper for ', el)
    Object.assign(this, EventEmitter)
    this.Events = EVENTS
    
    this.reset()
    
    el.interactive = true
    el.on('mousedown', this.touchStart)
    el.on('touchstart', this.touchStart)
    el.on('mousemove', this.touchMove)
    el.on('touchmove', this.touchMove)
    el.on('mouseup', this.touchEnd)
    if (options.out) {
      el.on('mouseout', this.touchEnd)
    }
    el.on('touchend', this.touchEnd)
  }
  
  get enabled () { return this._touch.enabled }
  get canClick () { return this._touch.canClick }
  get x () { return this._touch.x.final }
  get y () { return this._touch.y.final }
  get delta () { return { x: this._touch.x.delta, y: this._touch.y.delta } }
  
  set x (val) { this._touch.x.final = val }
  set y (val) { this._touch.y.final = val }
  
  touchStart = (e) => {
    const pos = e.data.global
    const x = ForceLandscape.portrait ? pos.y : pos.x
    const y = ForceLandscape.portrait ? pos.x : pos.y
    
    this._touch.enabled = true
    this._touch.x.current = this._touch.x.start = x
    this._touch.y.current = this._touch.y.start = y
    
    this._touch.canClick = true
    
    this.emit(EVENTS.START, this)
  }
  
  touchMove = (e) => {
    const pos = e.data.global
    const x = ForceLandscape.portrait ? pos.y : pos.x
    const y = ForceLandscape.portrait ? pos.x : pos.y
    if (!this._touch.enabled) return false
    
    this._touch.x.delta = this._touch.x.current - x
    this._touch.y.delta = this._touch.y.current - y
    this._touch.x.current = x
    this._touch.y.current = y
    this._touch.x.final += this._touch.x.delta
    this._touch.y.final += this._touch.y.delta
    
    // item click max
    if (Math.abs(this._touch.x.delta) > 10 || Math.abs(this._touch.y.delta) > 10) {
      this._touch.canClick = false
    }
    
    this.emit(EVENTS.MOVE, this)
  }
  
  touchEnd = (e) => {
    if (!this._touch.enabled) return false
    this._touch.enabled = false
    
    this.emit(EVENTS.END, this)
  }
  
  reset = () => {
    this._touch = {
      enabled: false,
      canClick: false,
      x: {
        start: 0,
        delta: 0,
        current: 0,
        final: 0
      },
      y: {
        start: 0,
        delta: 0,
        current: 0,
        final: 0
      }
    }
  }
}
