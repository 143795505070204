import * as mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'
import Cookies from 'js-cookie'

// VARIABLES
let urlParams = new window.URLSearchParams(window.location.search)
let openid = urlParams.get('_openid') || Cookies.get('_openid') || null
let isFollower = urlParams.get('_isFollower') || false
let username = urlParams.get('_username')

// INIT
const state = {
  openid: openid,
  isFollower: isFollower,
  username: username,
  ready: false
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
