// **************************************************
//
// RESIZE
//
// **************************************************
export const setDimensions = (state, payload) => {
  state.windowWidth = payload.width
  state.windowHeight = payload.height
}

export const setDevice = (state, payload) => {
  state.device = payload
}

export const setScale = (state, payload) => {
  state.scale = payload
}

export const setFontSize = (state, payload) => {
  state.fontSize = payload
}

export const setCountry = (state, payload) => {
  state.country = payload
}

export const setLowSpec = (state, value) => {
  state.isLowSpec = value
}
