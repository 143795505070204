import Scene from './Scene'
import * as PIXI from 'pixi.js'
import Lerp from '@/helpers/Lerp'
import ForceLandscape from '../helpers/ForceLandscape'
import ResizeService from '@/services/ResizeService'
import PixiService from '@/services/PixiService'
import router from '@/router'

class Foreground extends Scene {
  alpha = new Lerp()

  constructor () {
    const assets = [
      // {
      //   id: 'btn-home',
      //   url: require('@/assets/img/home.png')
      // },
      {
        id: 'btn-close',
        url: require('@/assets/img/close.png')
      }
    ]
    
    super(assets)
    
    // always show
    this.alwaysShown = true
  }
  
  setup () {
    this.closeButtons = new PIXI.Container()
    this.closeButtons.interactive = true
    this.closeButtons.cursor = 'pointer'
    this.closeButtons.on('click', this.onClose)
    this.closeButtons.on('tap', this.onClose)
    
    this.close = new PIXI.Sprite(this.loader.resources[`btn-close`].texture)
    this.closeButtons.addChild(this.close)
    
    this.closeButtons.scale.x = this.closeButtons.scale.y = 0.5
    this.stage.addChild(this.closeButtons)

    // !!! NOT USED FOR NOW
    // close only in play, no global close
    this.closeButtons.visible = false
  }
    
  onClose = (e) => {
    let route = router.currentRoute.name
    let play = PixiService.getScene('play')

    if (route === 'play' && play.state === play.STATES.MAIN) {
      // end game
      play.ended()
    } else {
      // return to select
      router.push('/')
    }    
  }
  
  resize () {
    let btns = this.closeButtons
    btns.x = ForceLandscape.width - btns.width - (ResizeService.scale * 40)
    btns.y = ResizeService.scale * 20
  }

  update () {
    this.alpha.process()
    this.close.alpha = this.alpha.current

    let alpha = 1
    let route = router.currentRoute.name
    let play = PixiService.getScene('play')
    if (
      (
        route === 'play' && 
        (!play.loaded || play.state !== play.STATES.MAIN)
      ) ||
      route === 'loading'
    ) {
      alpha = 0
    }

    // close active?
    let active = alpha === 1

    // master play ui?
    if (route === 'play' && play.state === play.STATES.MAIN) {
      alpha = play.timer.alphaTo.to
      active = true
    }

    this.alpha.to = alpha
    this.closeButtons.interactive = active
  }
}

export default new Foreground()
