export default [
  {
    name: 'waipoua',
    bg: require('@/assets/img/scenes/1/bg.jpg'),
    bgx2: require('@/assets/img/scenes/1/bg_x2.jpg'),
    sheet: `${process.env.BASE_URL}scenes/1/scene1.json`,
    close: require('@/assets/img/scenes/1/close.png'),
    hint: require('@/assets/img/scenes/1/hint.png'),
    bgm: 'forest_bgm',
    facts: [
      {
        title: 'Tall Timber',
        english: 'The tallest tree in New Zealand isn’t native. It’s an 80.5-metre tall Australian eucalyptus growing in Otago.',
        tereo: 'Ēhara tēnei rākau i te toi rākau ki Aotearoa, Nō Te Papakanui-a-Māui kē. He 80.5 mita te roa o te Purukamu Ahitereiriana. Kei te tipu tēnei rākau ki Ōtākou.'
      },
      {
        title: 'Forest Lord',
        english: `New Zealand's largest native tree is a kauri called Tane Mahuta or Lord of the Forest. It’s 45.2 metres tall. That’s big!`,
        tereo: 'Ko Tane Mahuta te tino toi rākau i Aotearoa, he kauri ia, ko ia te Ātua o te ngahere. He 45.2 mita te roa o tēnei rākau. He rahi rawa tērā!'
      },
      {
        title: 'A Land of Trees',
        english: 'Before people arrived in New Zealand around 80% of the land was covered in native forest.',
        tereo: 'I mua i te taenga mai o ngā tāngata ki Aotearoa, i wharikitia te toi ngahere i te waru tekau paihēneti (80%) o te whenua.'
      },
      {
        title: 'Wingless Wonders',
        english: 'The nine extinct species of moa were the only known birds that did not have wings!',
        tereo: 'E iwa ngā momo Moa ngaro, kāore ō rātou parirau.'
      },
      {
        title: 'Escar… Woah!',
        english: 'Powelliphanta is a type of giant carnivorous snail found only in New Zealand. They weigh up to 90g with a shell 9cm across.',
        tereo: 'He ngata kai miti te Powelliphanta, kei te noho tēnei ki Aotearoa anake. Ka tipu ēnei momo ngata ki 90 karamu , he 9 henimita te rahi o tōna anga.'
      },
      {
        title: 'Fast Fliers',
        english: 'Kārearea, the New Zealand falcon, can reach speeds of up to 230 kilometres an hour!',
        tereo: 'Parahutihuti ana te haere o te Kārearea, ko te tere, e rua rau toru tekau (230) kiromita i te haora.'
      },
    ]
  },
  {
    name: 'anp',
    bg: require('@/assets/img/scenes/2/bg.jpg'),
    bgx2: require('@/assets/img/scenes/2/bg_x2.jpg'),
    sheet: `${process.env.BASE_URL}scenes/2/scene2.json`,
    close: require('@/assets/img/scenes/2/close.png'),
    hint: require('@/assets/img/scenes/2/hint.png'),
    bgm: 'anp_bgm',
    facts: [
      {
        title: 'Flock Together',
        english: 'Back in 1982, New Zealand had 70.3 million sheep!',
        tereo: 'I ngā wā o mua i te tau kotahi mano iwa rau waru tekau mā rua (1982), he 70.3 miriona te nama o ngā hipi i Aotearoa!'
      },
      {
        title: 'Favourite Fruits',
        english: 'Kiwifruit is not native to New Zealand, but is originally from China!',
        tereo: 'Ēhara nō Aotearoa ngā huakiwi, Nō Haina kē!'
      },
      {
        title: 'Country Life',
        english: 'Around 45% of New Zealand’s total land area is used as farmland. That’s almost half!',
        tereo: 'Ko te nuinga o te whenua o Aotearoa tōtahi ki te whā tekau mā rima paihēneti (45%) he whenua pamu. Tata ki te hawhe o te katoa o te whenua nei.'
      },
      {
        title: 'What’s the Buzz?',
        english: 'New Zealand beekeepers own more than 600,000 hives and produce almost 20 thousand tonnes of honey!',
        tereo: 'Kua riro ngā kaitiaki pī o Aotearoa i te neke atu ki te ono rau mano (600,000) kāinga pī, Ka whakanao rātou i tata ki e rua tekau mano (20,000) tana o te mīere reka.'
      },
      {
        title: 'Holy Cow!',
        english: 'New Zealand has nearly 5 million milking cows that make around 21.0 billion litres of milk every year!',
        tereo: 'E rima miriona te nama o ngā kau miraka i Aotearoa, ka whakanao ngā kau i e rua tekau mā tahi piriona rita o te miraka ia tau, ia tau.'
      },
    ]
  },
  {
    name: 'cuba-st',
    bg: require('@/assets/img/scenes/3/bg.jpg'),
    bgx2: require('@/assets/img/scenes/3/bg_x2.jpg'),
    sheet: `${process.env.BASE_URL}scenes/3/scene3.json`,
    close: require('@/assets/img/scenes/3/close.png'),
    hint: require('@/assets/img/scenes/3/hint.png'),
    bgm: 'cuba_bgm',
    facts: [
      {
        title: 'Our Capital City',
        english: 'Wellington is the southernmost capital city in the world!',
        tereo: 'Ko Te Whanganui-a-Tara te tāone mātua o te Ao ki te tonga.'
      },
      {
        title: 'Now You’re Talking!',
        english: 'New Zealand has three official languages: English, Māori and New Zealand Sign Language.',
        tereo: 'E toru ngā reo ōkawa o Aotearoa, ko te reo Māori, te reo Pākēhā me te reo Rotarota.'
      },
      {
        title: 'The Big City',
        english: 'Auckland is home to over one and a half million people. More than the entire South Island!',
        tereo: 'He tokomaha ngā tāngata kei Tamakimakaurau e noho ana, tata ki te kotahi me te hawhe miriona. Koirā te neke atu i te nama katoa o ngā tāngata i Te Waipounamu.'
      },
      {
        title: 'Pass the Popcorn',
        english: 'New Zealand has a thriving film industry. Movies shot here, like The Lord of the Rings and Avatar, have earned billions at the box office!',
        tereo: 'He ahumahi kiriata tōnui tō Aotearoa, kua riro ngā kiriata pērā i te Lord of the rings me Avatar i ngā piriona tāra ki te Box Office!'
      },
      {
        title: 'Sky High!',
        english: 'At 328 metres tall, Auckland’s Sky Tower is the tallest freestanding structure in the Southern Hemisphere!',
        tereo: 'He toru rau rua tekau mā waru (328) mita te roa o te Sky Tower ki Tamakimakaurau, Kāore he mea i tiketike ake i tērā i te tuakoi tonga o te ao.'
      },
    ]
  },
  {
    name: 'the-coast',
    bg: require('@/assets/img/scenes/4/bg.jpg'),
    bgx2: require('@/assets/img/scenes/4/bg_x2.jpg'),
    sheet: `${process.env.BASE_URL}scenes/4/scene4.json`,
    close: require('@/assets/img/scenes/4/close.png'),
    hint: require('@/assets/img/scenes/4/hint.png'),
    bgm: 'the_coast_bgm',
    facts: [
      {
        title: 'Jack Be Nimble',
        english: 'Kaikaiawaro (Pelorus Jack) is the guardian of Ngāti Kuia, known for guiding ships through the dangerous waters around Cook’s Strait.',
        tereo: 'Ko Kaikaiawaro te kaitiaki o Ngāti Kuia, he rongonui tōna mahi ārahi kaipuke i ngā wai mōrearea o Raukawa Moana.'
      },
      {
        title: 'Coast to Coast',
        english: 'New Zealand has the 9th longest coastline in the world—longer than China, Italy or the United Kingdom!',
        tereo: 'Kei Aotearoa te tapātai tāroa tuaiwa o te ao, he roa atu tēnei i ngā tapātai o Haina, Itāria me Peretania.'
      },
      {
        title: 'Endangered Species ',
        english: `Found only in New Zealand, Māui's dolphin, or popoto, is the world’s rarest dolphin!`,
        tereo: 'Nō Aotearoa te Popoto, he aihe iti ia. Kei te noho tēnei aihe ki Aotearoa anake, he momo aihe mokomokorea te Popoto.'
      },
      {
        title: 'Small Wonder',
        english: 'The kororā, or little blue penguin, is the world’s smallest penguin growing to an average height of only 33cm.',
        tereo: 'He teoteo te Kororā, ko tēnei te momo kororā iti rawa i te ao katoa, ka tipu ēnei momo manu ki te toru tekau mā toru (33) henimita.'
      },
    ]
  },
  {
    name: 'rotorua',
    bg: require('@/assets/img/scenes/5/bg.jpg'),
    bgx2: require('@/assets/img/scenes/5/bg_x2.jpg'),
    sheet: `${process.env.BASE_URL}scenes/5/scene5.json`,
    close: require('@/assets/img/scenes/5/close.png'),
    hint: require('@/assets/img/scenes/5/hint.png'),
    bgm: 'rotorua_bgm',
    facts: [
      {
        title: '8th Wonder',
        english: 'The Pink and White Terraces were natural wonders thought destroyed in the 1886 eruption of Mount Tarawera.',
        tereo: 'I te tau kotahi mano waru rau waru tekau mā ono (1886) i mōti ngā tūāpapa o Ō-tu-kapua-rangi me Te Tarata i te hūnga o Tarawera Maunga.'
      },
      {
        title: 'Hot Spot',
        english: 'The word “geothermal” comes from the Greek words “geo” meaning ‘Earth’ and “thermos” meaning ‘heat’.',
        tereo: 'I heke mai te kupu Geothermal i te reo Kariki, he ōrite te kupu “geo” i te kupu “whenua” me “thermos” i te kupu “pūmāhu”.'
      },
      {
        title: 'Running out of Steam',
        english: 'Back in the 19th Century, there were around 220 geysers in New Zealand, but by 2004 only 58 were left.',
        tereo: 'I ngā wā o mua i te rautau 19 i Aotearoa, tērā atu, e 220 ngā puia, hēoi anō, i te tau 2004 i heke haere aua puia ki te 58.'
      },
      {
        title: 'Bubbling Up!',
        english: 'Geysers, mud pools and hot springs occur when underground water is heated and rises to the surface.',
        tereo: 'Orua tonu ngā ngāwhā, ngā waiariki me ngā puia i te wā ka pūmāhu te wai i raro i te whenua, kātahi ka puea ake.'
      },
      {
        title: 'High Water Mark',
        english: 'The largest geyser in New Zealand, Pōhutu, can shoot water up to 30 metres in the air!',
        tereo: 'Ko Pōhutu te ingoa o te puia tino nui rawa ki Aotearoa, Ka taea te wai wera o te Pōhutu ki te tarapī ake tae noa ki te toru tekau (30) mita.'
      },
    ]
  }
]
