import 'mutationobserver-shim'
import { clamp } from 'src/helpers/MathUtils'
import EventEmitter from 'src/helpers/EventEmitter'
import Raf from '@/helpers/Raf'

const Events = {
  RESIZE: 'resize',
}

let defaultConfig = {
  scale: 1,
  baseFontSize: 10,
  breakpoints: [
    // name, width, min-scale, max-scale
    ['mobile', 375, 0.55, 1], 
    ['tablet', 768, 0.55, 1], 
    ['desktop', 1280, 0.55, 1]
  ],
}

class ResizeService {
  constructor(config) {
    Object.assign(this, EventEmitter)

    this.scale = defaultConfig.scale
    this.config = Object.assign(defaultConfig, config)
    this.orientation = 'portrait'
    this.width = 0 // window.innerWidth
    this.height = 0 // window.innerHeight
    this.device = this.deviceType()
    this.html = document.querySelector('html')
    this.timer = {
      last: Date.now(),
      wait: 150
    }

    this.alwaysTrigger = false // always trigger resize event even if dimensions haven't changed
    this.onlyWidth = false // only monitor width changes

    this.isFacebook = /(fban|fbav)/i.test(navigator.userAgent.toLowerCase())
    this.isInstagram = /instagram/.test(navigator.userAgent.toLowerCase())
    this.isIOS = /(iOS|iPod|iPad|iPhone)/i.test(navigator.userAgent)
    this.isAndroid = /Android/.test(navigator.userAgent)
    this.isWechat = /micromessenger/i.test(navigator.userAgent)
    this.isWeibo = /weibo/.test(navigator.userAgent)
    this.isUCBrowser = /UCBrowser/.test(navigator.userAgent)

    this._onResize()
    this._setDeviceClasses()
    this._monitorHTMLStyles()

    // window.addEventListener('orientationchange', this._update, false)
    // window.addEventListener('resize', this._onResize)
    Raf.add(this._onResize)
  }

  destroy() {
    // window.removeEventListener('resize', this._onResize)
    Raf.remove(this._onResize)
  }

  setBreakpoints = (config) => {
    if (
      config.length > 0 &&
      typeof config[0][0] === 'string' &&
      typeof config[0][1] === 'number'
    ) {
      this.config.breakpoints = config
      this._onResize()
    } else console.log('Cannot add breakpoints, invalid format.')
  }

  setBaseFontSize = (val) => {
    this.config.baseFontSize = val
    this._onResize()
  }

  coverScale = (options) => {
    let config = Object.assign(
      {
        width: 0,
        height: 0,
        type: '',
        innerWidth: this.width,
        innerHeight: this.height,
      },
      options
    )

    let scaleW = config.innerWidth / config.width
    let scaleH = config.innerHeight / config.height
    let scale = Math.max(scaleW, scaleH)
    if (config.type === 'width') scale = scaleW
    if (config.type === 'height') scale = scaleH
    let width = config.width * scale
    let height = config.height * scale
    let x =
      Math.floor(width) === Math.floor(config.width)
        ? 0
        : (width - config.width) / 2
    let y =
      Math.floor(height) === Math.floor(config.height)
        ? 0
        : (height - config.height) / 2

    return {
      width: width,
      height: height,
      x: x,
      y: y,
      scale: scale,
    }
  }

  containScale = (options) => {
    let config = Object.assign(
      {
        width: 0,
        height: 0,
        innerWidth: this.width,
        innerHeight: this.height,
      },
      options
    )

    let scaleW = config.innerWidth / config.width
    let scaleH = config.innerHeight / config.height
    let scale = Math.min(scaleW, scaleH)
    let width = config.width * scale
    let height = config.height * scale

    return {
      width: width,
      height: height,
      scale: scale,
    }
  }

  remScaled = (options) => {
    let config = Object.assign(
      {
        width: 0,
        height: 0,
      },
      options
    )

    return {
      width: config.width * this.scale,
      height: config.height * this.scale,
    }
  }

  _monitorHTMLStyles = () => {
    this.htmlFontStyle = this.html.style.fontSize

    this.htmlObserver = new MutationObserver((mutations) => {
      for (let mutation of mutations) {
        if (mutation.attributeName === 'style') {
          let htmlFontStyle = this.html.style.fontSize

          if (this.htmlFontStyle !== htmlFontStyle) {
            if (htmlFontStyle === '') this._updateFontSize()
            this.htmlFontStyle = htmlFontStyle
          }
        }
      }
    })

    this.htmlObserver.observe(this.html, { attributes: true })
  }

  _resetTimer = (id, time, force) => {
    clearTimeout(this.timers[id])
    this.timers[id] = setTimeout(() => {
      this._resize(force)
    }, time)
  }

  _onResize = (force) => {
    // this._resize(force)
    // 
    // if (this.device === 'mobile') {
    //   this._resetTimer(0, 200, force)
    //   this._resetTimer(1, 500, force)
    //   this._resetTimer(2, 1000, force)
    // }
    
    let now = Date.now()
    if (now - this.timer.last > this.timer.wait) {
      this._resize()
      this.timer.last = now
    }
  }

  _resize = (force) => {
    let wW = window.innerWidth
    let wH = window.innerHeight
    
    // don't change if we're zoomed in...
    if (wW !== window.outerWidth && !force && this.device !== 'mobile') return

    // only continue if things changed
    if (wW === this.width && (wH === this.height || this.onlyWidth) && !force) {
      if (this.alwaysTrigger) {
        this.emit(Events.RESIZE, {
          width: this.width,
          height: this.height,
          device: this.device,
          orientation: this.orientation,
        })
      }
      return
    }

    this.width = wW
    this.height = wH
    this.device = this.deviceType()

    this._updateFontSize()
    this._setScaleClass()
    this._setScreenOrientationClass()

    this.emit(Events.RESIZE, {
      width: this.width,
      height: this.height,
      device: this.device,
      orientation: this.orientation,
    })
  }

  deviceType = (detail) => {
    let device = {
      type: this.config.breakpoints[0][0],
      width: this.config.breakpoints[0][1],
    }
    for (let b of this.config.breakpoints) {
      let type = b[0]
      let width = b[1]
      let min = b[2]
      let max = b[3]
      if (this.width > width) {
        device = {
          type: type,
          width: width,
          min: min,
          max: max
        }
      }
    }
    if (detail) return device
    else return device.type
  }

  _updateFontSize = () => {
    const device = this.deviceType(true)
    this.scale = clamp(this.width / device.width, device.min, device.max)
    this.fontSize = this.config.baseFontSize * this.scale
    this.html.style.fontSize = `${this.fontSize}px`

    // console.log('scale', this.scale)
  }

  _setScaleClass = () => {
    let device = this.deviceType()
    for (let b of this.config.breakpoints) {
      let type = b[0]
      if (type === device) this.html.classList.add(type)
      else this.html.classList.remove(type)
    }
  }

  _setScreenOrientationClass = () => {
    if (this.width > this.height) {
      this.html.classList.add('landscape')
      this.html.classList.remove('portrait')
      this.orientation = 'landscape'
    } else {
      this.html.classList.add('portrait')
      this.html.classList.remove('landscape')
      this.orientation = 'portrait'
    }
  }

  _setDeviceClasses = () => {
    if (this.isFacebook) this.html.classList.add('facebook')
    if (this.isInstagram) this.html.classList.add('instagram')
    if (this.isIOS) this.html.classList.add('ios')
    if (this.isAndroid) this.html.classList.add('android')
    if (this.isWechat) this.html.classList.add('wechat')
    if (this.isWeibo) this.html.classList.add('weibo')
    if (this.isUCBrowser) this.html.classList.add('ucbrowser')
  }
}

let service = new ResizeService()
service.Events = Events
window.ResizeService = service
export default service
