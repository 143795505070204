import { url } from '@pixi/utils';
import { LoaderResource } from '@pixi/loaders';
import * as PIXI from 'pixi.js'
/**
 * {@link PIXI.Loader Loader} middleware for loading texture atlases that have been created with
 * TexturePacker or similar JSON-based spritesheet.
 *
 * This middleware automatically generates Texture resources.
 *
 * @class
 * @memberof PIXI
 * @implements PIXI.ILoaderPlugin
 */
export default class ResnSpritesheetLoader extends PIXI.SpritesheetLoader
{
  /**
   * Called after a resource is loaded.
   * @see PIXI.Loader.loaderMiddleware
   * @param {PIXI.LoaderResource} resource
   * @param {function} next
   */
  static use(resource, next)
  {
    const imageResourceName = `${resource.name}_image`;
    // skip if no data, its not json, it isn't spritesheet data, or the image resource already exists
    if (!resource.data
      || resource.type !== LoaderResource.TYPE.JSON
      || !Array.isArray( resource.data )
      || !resource.data[0].frames
      || this.resources[imageResourceName]
    )
    {
      next();
      return;
    }

    //# Create separate resource objects for each spritesheet
    const vResources = resource.data.map( (sheet,i) => {
      return Object.assign(
          {},
          resource,
          {
            data: sheet,
            name: `${resource.name}_${i}`
          }
        )
    });

    let doneCount = 0;
    const nextHandler = err => {
      doneCount++;
      if( err ) {
        next(err);
      } else if( doneCount === vResources.length ) {

        //# Map the resulting spritesheet info back onto original loader resource
        resource.spritesheets = vResources.map( r => ({
          spritesheet: r.spritesheet,
          textures: r.textures
        }) );

        next();
      }
    };

    vResources.forEach( r => {
      super.use(r,nextHandler)
    });

  }
}
