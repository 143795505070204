<template lang="pug">
#home
</template>

<script>
export default {
  name: 'home',
}
</script>

<style lang="sass" scoped>
@import "src/styles/common.sass"

#home
  +full()
  +flex-center()
  // disable pointer events for bg touch
  pointer-events: none
  flex-direction: row
</style>
