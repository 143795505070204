import { getPointDistance, lerpPoint, randomInRange, getArcPoint, toRadians, clamp } from '@/helpers/MathUtils'

// made with PIXI.Graphics() in mind, but theoretically
// should work in regular canvas

export const curvyLine = (canvas, variance, x1, y1, x2, y2, moving = false) => {
  let current = 0
  let distance = getPointDistance({x: x1, y: y1}, {x: x2, y: y2})
  let xd = (x2 - x1) > 0 ? 1 : -1
  let yd = (y2 - y1) > 0 ? 1 : -1
  if (!moving) canvas.moveTo(x1, y1)
  
  while (current < distance) {
    current += randomInRange(20, 100)
    let perc = Math.min(distance, current) / distance
    let point = lerpPoint({x: x1, y: y1}, {x: x2, y: y2}, perc)
    let rx1 = randomInRange(0, variance) - (variance / 2) * xd
    let ry1 = randomInRange(0, variance) - (variance / 2) * yd
    canvas.quadraticCurveTo(point.x + rx1, point.y + ry1, point.x, point.y)
  }    

  let rx1 = randomInRange(0, variance) - (variance / 2) * xd
  let ry1 = randomInRange(0, variance) - (variance / 2) * yd
  canvas.quadraticCurveTo(x2 + rx1, y2 + ry1, x2, y2)
}

export const curvyRect = (canvas, x, y, w, h, r) => {
  curvyLine(canvas, r * 0.1, x, y, x + w, y, true)
  curvyLine(canvas, r * 0.1, x + w, y, x + w, y + h, true)
  curvyLine(canvas, r * 0.1, x + w, y + h, x, y + h, true)
  curvyLine(canvas, r * 0.1, x, y + h, x, y, true)
}

export const curvyCircle = (canvas, x, y, r) => {
  let current = 0
  let distance = 360
  let variance = r * 0.1
  let nx = x + r
  let ny = y + r
  let point = getArcPoint(nx, ny, r, toRadians(-90 + current))
  let px = point.x
  let py = point.y
  canvas.moveTo(px, py)
  
  while (current < distance) {
    current += randomInRange(r * 0.1, r * 0.3)
    let perc = clamp(current / distance, 0, 1)
    point = getArcPoint(nx, ny, r, toRadians(-90 + (perc * distance)))
    let xd = (point.x - px) > 0 ? 1 : -1
    let yd = (point.y - py) > 0 ? 1 : -1
    let rx1 = randomInRange(0, variance) - (variance / 2) * xd
    let ry1 = randomInRange(0, variance) - (variance / 2) * yd
    canvas.quadraticCurveTo(point.x + rx1, point.y + ry1, point.x, point.y)
    px = point.x
    py = point.y
  }    
}
