import FontFaceObserver from 'fontfaceobserver'
import EventEmitter from '@/helpers/EventEmitter'

const EVENTS = {
  LOADED: 'loaded'
}

class FontService {
  loaded = false
  
  constructor () {
    Object.assign(this, EventEmitter)
    this.Events = EVENTS
  }
  
  load = async (manifest) => {
    for (let item of manifest) {
      let font = new FontFaceObserver(item.name, item.options)
      await font.load()
    }
    this.loaded = true
    this.emit(EVENTS.LOADED, this)
  }
}

let service = new FontService()
export default service
