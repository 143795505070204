import Scene from './Scene'
import * as PIXI from 'pixi.js'
import Lerp from '@/helpers/Lerp'
import Touch from '../helpers/Touch'
import { clamp, toRadians, randomIntInRange } from '@/helpers/MathUtils'
import tutorial from '../manifests/Tutorial'
import anime from 'animejs'
import ForceLandscape from '../helpers/ForceLandscape'
import PixiService from '@/services/PixiService'
import FontService from '@/services/FontService'
import Padded from '../helpers/Padded'
import Sounds from '@/services/PixiService/Sounds'

class Tutorial extends Scene {
  card = new PIXI.Container()
  items = []
  nav = new PIXI.Container()
  bullets = []
  _current = -1
  total = 0
    
  constructor () {
    const assets = []

    // add tutorial layer assets
    tutorial.forEach(t => {
      t.layers.forEach(l => {
        if (!l.url) return
        // make a copy to rename the layer ID to be more specific
        let layer = Object.assign({}, l)
        layer.id = `${t.id}-${l.id}`
        assets.push(layer)
      })
    })
    
    super(assets)
  }

  setup () {
    this.touch = new Touch(this.stage, { out: true })

    if (FontService.loaded) this.makeThings()
    else FontService.on(FontService.Events.LOADED, this.makeThings)      
  }

  makeThings = () => {
    this.makeBg()
    this.makeCarousel()
    this.makeSkip()
    this.makeNext()    
        
    this.card.pivot.x = this.card.width / 2
    this.card.pivot.y = this.card.height / 2
    this.stage.addChild(this.card)
  }

  makeBg () {
    let bg = new PIXI.Graphics()
    bg.beginFill(0xffffd6)
    bg.drawRoundedRect(0, 0, 514, 280, 10)
    bg.endFill()
    this.card.addChild(bg)
  }

  makeCarousel () {
    tutorial.forEach((t, index) => {
      let group = new PIXI.Container()
      group.xAdd = 0 // for animating later

      t.layers.forEach(l => {
        let s

        // image
        if (l.url) s = new PIXI.Sprite(this.loader.resources[`${t.id}-${l.id}`].texture)

        // text
        if (l.text) s = new PIXI.Text(l.text, l.style)

        // placement
        s.scale.x = s.scale.y = 0.5
        if (l.x) s.x = l.x * 0.5
        if (l.y) s.y = l.y * 0.5
        group.addChild(s)
      })

      group.x = (this.card.width / 2) - (group.width / 2)
      group.y = (this.card.height / 2) - ((group.height + 45) / 2)
      this.card.addChild(group)
      this.items.push(group)

      // nav
      let bul = new PIXI.Graphics()
      bul.beginFill(0xe6754b)
      bul.drawCircle(12, 12, 12)
      bul.endFill()
      bul.x = index * 55
      bul.pivot.x = bul.pivot.y = 12
      this.bullets.push(bul)
      this.nav.addChild(bul)
    })

    this.total = tutorial.length

    // nav
    this.nav.scale.x = this.nav.scale.y = 0.5
    this.nav.x = (this.card.width / 2) - (this.nav.width / 2)
    this.nav.y = this.items[0].y + this.items[0].height + 34
    this.card.addChild(this.nav)
  }

  makeSkip () {
    let skip = new Padded(new PIXI.Text('SKIP', {
      fontFamily: 'Avenir Black',
      fontSize: 15,
      fill: 0xe6754b,
      trim: true
    }), 10 * PixiService.resolution, 10 * PixiService.resolution)
    this.skip = skip
    skip.alpha = 0.5
    skip.x = 35
    skip.y = 222
    skip.interactive = true
    skip.cursor = 'pointer'
    skip.on('click', this.onSkip)
    skip.on('tap', this.onSkip)
    this.card.addChild(skip)
  }

  makeNext () {
    let next = new Padded(new PIXI.Text('NEXT', {
      fontFamily: 'Avenir Black',
      fontSize: 15,
      fill: 0xe6754b,
      trim: true
    }), 10 * PixiService.resolution, 10 * PixiService.resolution)
    this.next = next
    next.x = 410
    next.y = 222
    next.interactive = true
    next.cursor = 'pointer'
    next.on('click', this.onNext)
    next.on('tap', this.onNext)
    this.card.addChild(next)
  }

  updateNav () {
    let play = PixiService.getScene('play')
    if (play.mode === play.MODES.CASUAL) {
      this.total = tutorial.length - 1
      this.bullets[this.bullets.length - 1].visible = false
    } else {
      this.total = tutorial.length
      this.bullets[this.bullets.length - 1].visible = true
    }

    this.nav.x = (this.card.width / 2) - (this.nav.width / 2)  
  }
  
  shown () {
    this.stage.alpha = 1
    this.skip.interactive = true
    this.next.interactive = true
    this.current = 0
    this.updateNav()
  }

  get current () {
    return this._current
  }

  set current (val) {
    let current = this._current
    if (current === val) return

    // items
    this.items.forEach((i, index) => {
      anime.remove([i])
      if (index === val) {
        // show item
        anime({
          targets: [i],
          xAdd: [100 * (val > current ? 1 : -1), 0],
          alpha: 1
        })
      } else {
        // hide item
        anime({
          targets: [i],
          xAdd: [0, 100 * (val > current ? -1 : 1)],
          alpha: 0
        })
      }
    })

    // nav
    this.bullets.forEach((b, index) => {
      anime.remove([b])
      if (index === val) {
        // current
        anime({
          targets: [b.scale],
          x: 1,
          y: 1
        })
      } else {
        // not
        anime({
          targets: [b.scale],
          x: 0.5,
          y: 0.5
        })
      }
    })

    // swipe sound
    Sounds.next.play(true)

    this._current = val
  }
  
  onSkip = async () => {
    if (!this.active) return
    await anime({
      targets: this.stage,
      alpha: [1, 0],
      // y: -this.stage.height * 1.5,
      easing: 'easeOutExpo',
      duration: 500
    })
    this.skip.interactive = false
    this.next.interactive = false

    // swipe sound
    Sounds.next.play(true)

    // LET PLAY KNOW WE'RE READY HERE
    this.leave()
  }

  onNext = () => {
    let current = this.current
    let total = this.total // this.items.length
    if (current < total - 1) {
      this.current += 1
    } else {
      this.onSkip()
    }
  }

  fadein (callback) {
    let x = (ForceLandscape.width / 2)
    let y = (ForceLandscape.height / 2)

    this.active = true
    anime.remove(this.stage)      
    anime({
      targets: this.stage,
      alpha: [0, 1],
      y: [y + (this.stage.height * 0.5), y],
      duration: 750,
      complete: () => {
        if (callback) callback()
      }
    })
  }

  resize () {
    this.stage.x = (ForceLandscape.width / 2)
    this.stage.y = (ForceLandscape.height / 2)
  }
  
  fadeout (callback) {
    let x = (ForceLandscape.width / 2)
    let y = (ForceLandscape.height / 2)

    anime.remove(this.stage)
    anime({
      targets: this.stage,
      alpha: [1, 0],
      y: [y, y - (ForceLandscape.height / 2)],
      duration: 750,
      easing: 'easeOutExpo',
      complete: () => {
        this.active = false
        if (callback) callback()
      }
    })
  }  
  
  update () {
    // general positioning
    let slides = this.items
    let card = this.card
    for (let x = 0; x < slides.length; x++) {
      let slide = slides[x]
      slide.x = (card.width / 2) - (slide.width / 2) + slide.xAdd
    }

    // swiping
    if (Math.abs(this.touch.x) > 50) {
      let d = this.touch.x < 0 ? -1 : 1
      let c = this.current
      let t = this.total - 1 // this.items.length - 1

      if (c === t && d === 1) {
        this.onSkip()
      }

      this.touch.x = 0
      this.current = clamp(c + d, 0, t)
      this.touch.touchEnd()
    }
  }
}

export default new Tutorial()
